[data-aos="small-text-anim"] {
  transform: translateY(6px);
  opacity: 0;
  transition-property: transform, opacity;
  &.aos-animate {
    transform: translateY(0);
    opacity: 1;
  }
}

[data-aos="border-tl-big"] {
  transition-property: border-radius;
  &.aos-animate {
    border-radius: 100px 20px 20px 20px;
    @include media-breakpoint-down(sm) {
      border-radius: 35px 20px 20px 20px;
    }
  }
}

[data-aos="border-tl-small"] {
  transition-property: border-radius;
  &.aos-animate {
    border-radius: 50px 20px 20px 20px;
    @include media-breakpoint-down(sm) {
      border-radius: 35px 20px 20px 20px;
    }
  }
}

[data-aos="border-tr-big"] {
  transition-property: border-radius;
  &.aos-animate {
    border-radius: 20px 100px 20px 20px;
    @include media-breakpoint-down(sm) {
      border-radius: 20px 35px 20px 20px;
    }
  }
}

[data-aos="border-tr-small"] {
  transition-property: border-radius;
  &.aos-animate {
    border-radius: 20px 50px 20px 20px;
    @include media-breakpoint-down(sm) {
      border-radius: 20px 35px 20px 20px;
    }
  }
}

[data-aos="border-bl-big"] {
  transition-property: border-radius;
  &.aos-animate {
    border-radius: 20px 20px 20px 100px;
    @include media-breakpoint-down(sm) {
      border-radius: 20px 20px 20px 35px;
    }
  }
}

[data-aos="border-bl-small"] {
  transition-property: border-radius;
  &.aos-animate {
    border-radius: 20px 20px 20px 50px;
    @include media-breakpoint-down(sm) {
      border-radius: 20px 20px 20px 35px;
    }
  }
}

[data-aos="border-br-big"] {
  transition-property: border-radius;
  &.aos-animate {
    border-radius: 20px 20px 100px 20px;
    @include media-breakpoint-down(sm) {
      border-radius: 20px 20px 35px 20px;
    }
  }
}

[data-aos="foto-br-big"] {
  transition-property: border-radius;
  &.aos-animate {
    border-radius: 20px 20px 100px 20px;
  }
}

[data-aos="foto-tr-big"] {
  transition-property: border-radius;
  &.aos-animate {
    border-radius: 20px 100px 20px 20px;
  }
}

[data-aos="foto-tl-big"] {
  transition-property: border-radius;
  &.aos-animate {
    border-radius: 100px 20px 20px 20px;
  }
}

[data-aos="foto-tr-big"] {
  transition-property: border-radius;
  &.aos-animate {
    border-radius: 20px 100px 20px 20px;
  }
}

[data-aos="border-br-small"] {
  transition-property: border-radius;
  &.aos-animate {
    border-radius: 20px 20px 50px 20px;
    @include media-breakpoint-down(sm) {
      border-radius: 20px 20px 35px 20px;
    }
  }
}

[data-aos="rotate-always-big"] {
  transition-property: animation;
  &.aos-animate {
    animation: spinning 40s linear infinite;
  }
}

[data-aos="rotate-always-small"] {
  transition-property: animation;
  &.aos-animate {
    animation: spinning 20s linear infinite;
  }
}

@keyframes spinning {
  100% {
    transform: rotate(360deg);
  }
}

[data-aos="orbit-rotate-once-long"] {
  transition-property: animation;
  &.aos-animate {
    animation: spin-small 4s;
  }
}

[data-aos="orbit-rotate-once-quick"] {
  transition-property: animation;
  &.aos-animate {
    animation: spin-small 2s;
  }
}

[data-aos="orbit-rotate-once-brian-small"] {
  transition-property: animation;
  &.aos-animate {
    animation: spin-small 2s;
  }
}

[data-aos="orbit-rotate-once-brian-big"] {
  transition-property: animation;
  &.aos-animate {
    animation: spin-small-reverse 4s;
  }
}

[data-aos="orbit-rotate-loop"] {
  transition-property: animation;
  &.aos-animate {
    animation: spin-full 30s linear infinite;
  }
}

[data-aos="orbit-rotate-loop-slow"] {
  transition-property: animation;
  &.aos-animate {
    animation: spin-full 40s linear infinite;
  }
}

[data-aos="orbit-rotate-loop-reverse"] {
  transition-property: animation;
  &.aos-animate {
    animation: spin-full-reverse 30s linear infinite;
  }
}

[data-aos="border-tl-small-m"] {
  transition-property: border-radius;
  &.aos-animate {
    border-radius: 50px 20px 20px 20px;
    @include media-breakpoint-down(sm) {
      border-radius: 35px 20px 20px 20px;
    }
    @include media-breakpoint-down(sm) {
      border-radius: 20px 10px 10px 10px;
    }
  }
}

[data-aos="border-tr-small-m"] {
  transition-property: border-radius;
  &.aos-animate {
    border-radius: 20px 50px 20px 20px;
    @include media-breakpoint-down(sm) {
      border-radius: 10px 35px 10px 10px;
    }
    @include media-breakpoint-down(sm) {
      border-radius: 10px 20px 10px 10px;
    }
  }
}

[data-aos="border-bl-small-m"] {
  transition-property: border-radius;
  &.aos-animate {
    border-radius: 20px 20px 20px 50px;
    @include media-breakpoint-down(sm) {
      border-radius: 10px 10px 10px 35px;
    }
    @include media-breakpoint-down(sm) {
      border-radius: 10px 10px 10px 20px;
    }
  }
}

[data-aos="border-br-small-m"] {
  transition-property: border-radius;
  &.aos-animate {
    border-radius: 20px 20px 50px 20px;
    @include media-breakpoint-down(sm) {
      border-radius: 10px 10px 35px 10px;
    }
    @include media-breakpoint-down(sm) {
      border-radius: 10px 10px 20px 10px;
    }
  }
}

[data-aos="row-line-drawing"] {
  width: 0;
  transition-property: width;
  &.aos-animate {
    width: calc(100% - 30px);
  }
}

[data-aos="row-line-drawing--small"] {
  width: 0;
  transition-property: width;
  &.aos-animate {
    width: calc(100% - 24px);
  }
}

[data-aos="header-reveal"] {
  transition-property: opacity, transform, animation;
  &.aos-animate {
    animation: revealText 500ms cubic-bezier(0.7, 0, 0.3, 1) forwards;
    backface-visibility: hidden;
  }
}

[data-aos="vertical-line-tb"] {
  height: 0;
  transition-property: height;
  &.aos-animate {
    height: 100%;
  }
}

[data-aos="show-number"] {
  transition-property: color, background-color;
  &.aos-animate {
    background-color: $blue;
    color: white;
  }
}

[data-aos="dot-activation"] {
  fill: $light2;
  transition-property: fill;
  &.aos-animate {
    fill: $blue;
  }
}

[data-aos="separator-dot-activation"] {
  background-color: $light2;
  transition-property: background-color;
  &.aos-animate {
    background-color: $blue;
  }
}

[data-aos="dot-line-rotate"] {
  transform: rotate(0);
  transition-property: transform;
  &.aos-animate {
    transform: rotate(180deg);
  }
}

[data-aos="panning"] {
  transform: translateX(-20px) translateY(40px) scale(1.25);
  transition-property: transform;
  &.aos-animate {
    transform: translateX(-20px) translateY(20px) scale(1.15);
  }
}
