.popup {
  width: 100%;
  height: 100%;
  box-shadow: -2px 14px 33px -13px rgba(224, 221, 224, 1);
  position: relative;
  overflow: auto;
  background-color: white;
  padding: 120px 24px 30px 24px;
  border: 1px solid transparent;
  border-radius: 5px;
  scrollbar-color: rgba(0, 0, 0, 0.2);
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    display: block;
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 6px;
    border-radius: 6px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $blue;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  @include media-breakpoint-up(md) {
    padding: 6rem;
  }
  @include media-breakpoint-up(lg) {
    padding: 11rem;
  }
}

.form-group {
  margin-bottom: 1.6rem;
}

input,
textarea {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid $light4;
  border-radius: 10px;
  margin: 0;
  padding: 1.5rem 2rem;
  transition: 0.3s;
  color: $blue;
  &::placeholder {
    color: $blue;
    font-size: 1.8rem;
    font-weight: 600;
  }
  &:focus,
  &:active {
    border: 1px solid $blue;
    outline: 0 none;
  }
}

textarea {
  min-height: 7.3rem;
  @include media-breakpoint-up(lg) {
    min-height: 12rem;
  }
}

input {
  &[type="text"] {
    word-wrap: break-word;
    word-break: break-all;
  }
  &[type="checkbox"] {
    position: relative;
    width: 20px;
    height: 20px;
    left: 0;
    z-index: 10;
    opacity: 0;
    margin-right: 16px;
    float: left;
    margin-bottom: 0;
    & + span:before {
      content: "";
      display: block;
      width: 22px;
      height: 22px;
      position: absolute;
      left: 0;
      top: 3px;
      border: 1px solid grey;
      border-radius: 5px;
      transition: background 0.3s ease, border-color 0.3s ease;
    }
    &:checked + span:before {
      content: url("../images/check.svg");
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &[type="submit"] {
    display: inline-block;
    width: auto;
  }
}

.submit-wrapper {
  margin-top: 1rem;
  max-width: 669px;
  @include media-breakpoint-up(lg) {
    margin-top: 2.4rem;
  }
}

label,
legend {
  display: block;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.45;
  display: block;
  margin-bottom: 1rem;
}

fieldset {
  margin-top: 3.2rem;
}

legend {
  margin-bottom: 1.4rem;
}

.popup-form-wrapper {
  padding-bottom: 6.4rem;
  max-width: 888px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  position: relative;
  @include media-breakpoint-up(md) {
    padding-top: 6.4rem;
  }
  .popup-form,
  .step2-wrapper {
    max-width: 661px;
    margin-left: auto;
    margin-right: auto;
  }
  .step2-wrapper {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    @media screen and (min-width: 602px) {
      text-align: left;
      justify-content: space-between;
    }
    .hero-intro {
      margin-bottom: 7.2rem;
      font-size: 1.8rem;
      @include media-breakpoint-up(md) {
        font-size: 2.4rem;
      }
    }
    .section__title {
      @include media-breakpoint-down(md) {
        line-height: 1.4;
      }
    }
    .title-boxed {
      background-color: $lightblue;
      position: absolute;
      top: -40px;
      left: 50%;
      transform: translateX(-50%);
      @media screen and (min-width: 602px) {
        left: 0;
        transform: none;
      }
    }
  }
}

.popup-wrapper {
  position: fixed;
  z-index: 9999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.6);
  display: none;
  .hero-title {
    margin-bottom: 3.2rem;
    @include media-breakpoint-up(md) {
      margin-bottom: 6.4rem;
    }
  }
}

.close-btn,
.calendly-popup-close {
  position: fixed;
  right: 33px;
  top: 33px;
  width: 48px;
  height: 48px;
  background-image: url("../assets/images/icons/close.svg");
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10;
  &:hover {
    background-image: url("../assets/images/icons/close.svg");
    box-shadow: none;
  }
}

.popup-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
}

.form-step1 {
  max-width: 888px;
  margin-left: auto;
  margin-right: auto;
}

.form-step2 {
  display: none;
}

.form-step3 {
  display: none;
}

.js-ajax-form-message {
  display: none;
}

.form__group-errors {
  display: block;
  margin: 0;
  padding: 3px 0 0;
  li {
    display: block;
    margin: 0;
    padding: 0;
    color: red;
    font-size: 12px;
    list-style-type: none;
    @include media-breakpoint-up(lg) {
      font-size: 14px;
    }
  }
}

.form-message {
  display: none;
  width: 100%;
  margin: 15px 0 0;
  padding: 15px 30px;
  font-size: 15px;
  border-radius: 5px;
  text-align: center;
  @include media-breakpoint-up(lg) {
    padding-right: 20px;
    padding-left: 20px;
    font-size: 17px;
  }
}

.h-form {
  display: none;
}

.pricing-thanks {
  p {
    font-size: 2.4rem;
    font-weight: 600;
  }
}

.form-step3 {
  display: none;
}

.popup.popup-shrink {
  height: auto;
}

.radio-container {
  position: relative;
  padding-left: 41px;
  transition: $transition-time;
  font-weight: 500;
  &:hover {
    cursor: pointer;
  }
}

.radio-container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: auto;
}

.radio-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: transparent;
  border: 1px solid #d9dede;
  border-radius: 50%;
  transition: 0.3s background-color;
}

.radio-container:hover input ~ .checkmark {
  background-color: $light4;
}

.radio-container input:checked ~ .checkmark {
  background-color: white;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radio-container input:checked ~ .checkmark:after {
  display: block;
}

.radio-container .checkmark:after {
  top: 7px;
  left: 7px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: $lightblue;
}

@-webkit-keyframes autofill {
  0%,
  100% {
    color: #666;
    background: transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-delay: 1s;
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}
