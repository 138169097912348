// HOME page start
#hero {
  &-square1 {
    left: -70px;
    top: -30px;
    @media screen and (min-width: 1299px) {
      left: -120px;
    }
    @media screen and (min-width: 1920px) {
      left: -200px;
    }
  }
  &-square2 {
    right: -60px;
    top: 150px;
    @media screen and (min-width: 1799px) {
      right: -170px;
    }
  }
  &-square3 {
    bottom: 160px;
    left: 12px;
  }
  &-square4 {
    bottom: 0;
    left: 12px;
  }
}
#roots {
  &-square1 {
    @include media-breakpoint-down(xl) {
      right: 8px;
      top: -100px;
      z-index: -1;
    }
    .square--big {
      top: 118px;
      right: 8px;
      width: 88px;
      height: 88px;
      @include media-breakpoint-up(md) {
        top: 118px;
        right: 16px;
        width: 204px;
        height: 204px;
      }
    }
    @include media-breakpoint-up(xl) {
      bottom: 160px;
      left: 12px;
      z-index: -2;
    }
  }
  &-square2 {
    width: 100%;
    height: 277px;
    left: 0;
    top: 0;
    z-index: -1;
    @include media-breakpoint-down(lg) {
      top: 50%;
      transform: translateY(-50%);
      z-index: -1;
    }
    @media (min-width: 768px) {
      height: 430px;
    }
    @media screen and (max-width: 350px) {
      width: 234px;
    }
    @include media-breakpoint-up(xl) {
      height: 624px;
      bottom: 0;
      transform: none;
    }
    .custom-size {
      width: 100%;
      height: 100%;
      position: relative;
      left: 0;
      top: unset;
      z-index: -1;
    }
    @include media-breakpoint-up(xl) {
      width: calc(100% - 24px);
      height: 624px;
      top: unset;
      bottom: 0;
      .custom-size {
        height: 100%;
        position: relative;
      }
    }
  }
  &-square3 {
    bottom: 60%;
    @include media-breakpoint-down(lg) {
      right: 8px;
      z-index: 2;
    }
    .square--small {
      @include media-breakpoint-down(sm) {
        width: 45px;
        height: 45px;
      }
    }
    @include media-breakpoint-up(lg) {
      left: 126px;
      bottom: -50px;
    }
  }
}
#horizon {
  &-square1 {
    width: calc(100% - 32px);
    height: 616px;
    bottom: 0;
    left: 12px;
    .custom-size {
      width: 100%;
      height: 100%;
      position: relative;
      left: 0;
      bottom: 0;
    }
  }
  &-square2 {
    top: 0;
    right: 16px;
  }
  &-square3 {
    bottom: 0;
    left: -20px;
    .square--small {
      @include media-breakpoint-down(sm) {
        width: 28px;
        height: 28px;
      }
    }
    @include media-breakpoint-up(lg) {
      bottom: 0;
      right: 16px;
    }
  }
  &-square4 {
    .square--big {
      @include media-breakpoint-down(lg) {
        bottom: 125px;
        left: 270px;
      }
      @include media-breakpoint-down(sm) {
        width: 64px;
        height: 64px;
      }
      @include media-breakpoint-up(sm) {
        width: 120px;
        height: 120px;
      }
      @include media-breakpoint-up(xl) {
        width: 204px;
        height: 204px;
      }
    }
    bottom: 0;
    left: 0;
  }
  &-square5 {
    bottom: 0;
    left: -104px;
    @include media-breakpoint-up(lg) {
      bottom: 0;
      right: 16px;
    }
  }
  &-orbit1-d {
    @include media-breakpoint-up(lg) {
      bottom: -86px;
      right: -86px;
    }
  }
  &-orbit1-m {
    bottom: 33px;
    left: 0px;
  }
  &-orbit2 {
    top: 48%;
    transform: translateY(-73%);
    right: -24px;
    @include media-breakpoint-up(sm) {
      right: -20px;
    }
    @include media-breakpoint-up(lg) {
      top: 50%;
      transform: translateY(-50%);
      right: 0;
    }
  }
}
#success {
  &-square1 {
    width: calc(100% - 32px);
    height: 432px;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    .custom-size {
      width: 100%;
      height: 100%;
      position: relative;
      left: 0;
      bottom: 0;
    }
  }
  &-square2 {
    left: 8px;
    top: 0;
    @include media-breakpoint-up(lg) {
      top: 60%;
      left: -24px;
    }
    .square--small {
      @include media-breakpoint-down(xs) {
        width: 38px;
        height: 38px;
      }
    }
  }
  &-square3 {
    top: 0;
    right: 0;
  }
  &-square4 {
    top: 30%;
    right: -38px;
    @include media-breakpoint-down(sm) {
      right: 24px;
    }
    .square--small {
      @include media-breakpoint-down(xs) {
        width: 38px;
        height: 38px;
      }
    }
    @include media-breakpoint-up(lg) {
      right: 12px;
      top: 0;
      z-index: 3;
    }
  }
  &-square5 {
    bottom: -100px;
    right: 16px;
  }
  &-square6 {
    right: unset;
    z-index: -1;
    @include media-breakpoint-up(lg) {
      right: 16px;
    }
    .square--big {
      @include media-breakpoint-down(lg) {
        width: 90px;
        height: 90px;
      }
    }
  }
  &-square7 {
    right: 24px;
    top: 5%;
    z-index: -1;
    @include media-breakpoint-up(lg) {
      top: 75%;
      right: -112px;
      z-index: 1;
    }
    .square--big {
      @include media-breakpoint-down(sm) {
        width: 90px;
        height: 90px;
      }
    }
  }
  &-orbit1 {
    top: -50px;
    right: -47px;
    @include media-breakpoint-up(xl) {
      bottom: -50px;
      right: unset;
      top: unset;
      left: 0;
    }
  }
  &-orbit2 {
    z-index: -1;
    top: 30%;
    right: 66%;
    @include media-breakpoint-up(md) {
      top: 0;
      right: 24px;
    }
  }
}
#opinions {
  &-orbit1,
  &-orbit2 {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
}
#hero {
  &-orbit1 {
    left: -100px;
    top: 0;
    z-index: -1;
    @media screen and (min-width: 1399px) {
      left: -250px;
      top: 5px;
    }
  }
  &-orbit2 {
    right: -14px;
    bottom: unset;
    top: 155px;
    z-index: 2;
    @include media-breakpoint-up(sm) {
      right: -10px;
      top: 160px;
    }
    @media screen and (min-width: 1920px) {
      bottom: 220px;
    }
  }
  &-orbit3 {
    left: -23px;
    bottom: 0;
    top: 395px;
    z-index: 2;
    @include media-breakpoint-up(lg) {
      top: unset;
      left: 50%;
      bottom: -102px;
      transform: translateX(-50%);
    }
    @media screen and (min-width: 1920px) {
      bottom: -102px;
    }
  }
}
#logo-orbit2,
#logo-orbit1 {
  z-index: -1;
}
#roots {
  &-orbit1 {
    bottom: 36px;
    left: -16px;
    @include media-breakpoint-up(lg) {
      bottom: 20px;
      transform: translateY(-50%);
      left: 37px;
    }
    @include media-breakpoint-up(xl) {
      bottom: 28px;
      transform: translateY(-50%);
      left: -30px;
    }
  }
  &-orbit2 {
    top: 12%;
    right: 20px;
    @include media-breakpoint-down(sm) {
      z-index: -2;
    }
    @include media-breakpoint-up(sm) {
      top: 6%;
      right: 133px;
    }
    @include media-breakpoint-up(lg) {
      top: 47%;
      transform: translateY(-50%);
      right: 0;
      z-index: -1;
    }
  }
}
#difference {
  &-orbit1 {
    top: -255px;
    left: 16px;
    @include media-breakpoint-up(lg) {
      top: -102px;
      left: -102px;
    }
  }
  &-orbit2 {
    bottom: -40px;
    right: -22px;
    z-index: 1;
  }
}
// HOMEP page end

// ABOUT page start
#about {
  &-square1 {
    top: -7rem;
  }
  &-square2 {
    top: -3.4rem;
    left: 54px;
    z-index: -1;
    @include media-breakpoint-up(lg) {
      top: 6.4rem;
      left: unset;
      right: 12px;
    }
  }
  &-square3 {
    bottom: 0;
    display: flex;
    align-items: flex-end;
    right: 12px;
  }
  &-square4 {
    bottom: 0;
    display: flex;
    right: 0;
    @include media-breakpoint-up(lg) {
      right: -102px;
    }
  }
  &-square5 {
    bottom: 0;
    display: flex;
    left: -90px;
    z-index: -1;
    @include media-breakpoint-up(xl) {
      left: -216px;
      bottom: 8rem;
    }
  }
  &-square6 {
    top: 30%;
    left: -120px;
    @include media-breakpoint-up(xl) {
      left: -216px;
    }
  }
  &-square7 {
    top: 50%;
    right: -120px;
    @include media-breakpoint-up(xl) {
      right: -202px;
    }
  }
  &-square8 {
    position: absolute;
    top: 23rem;
    left: 65%;
    transform: translateX(-50%);
    z-index: 2;
    @media screen and (max-width: 375px) {
      display: none;
    }
    @media screen and (min-width: 375px) and (max-width: 380px) {
      display: block;
    }
    @media screen and (min-width: 381px) and (max-width: 767px) {
      display: none;
    }
    @include media-breakpoint-up(md) {
      display: block;
      top: unset;
      bottom: 90px;
    }
    @include media-breakpoint-up(lg) {
      bottom: -32px;
    }
    @include media-breakpoint-up(xl) {
      bottom: -30px;
    }
  }
  &-square9 {
    top: 50%;
    display: flex;
    right: -102px;
    @include media-breakpoint-up(lg) {
      right: -102px;
    }
  }
  &-square10 {
    bottom: 5rem;
    display: flex;
    align-items: flex-end;
    left: 192px;
    z-index: 3;
  }
  &-square11 {
    bottom: 170px;
    left: -102px;
  }
  &-square12 {
    top: 80px;
    left: 12px;
  }
  &-square13 {
    top: 60rem;
    right: -202px;
    z-index: 2;
  }
  &-square14 {
    position: absolute;
    left: 12px;
  }
  &-square15 {
    position: absolute;
    left: 126px;
    top: 50%;
  }
  &-square16 {
    position: absolute;
    display: flex;
    align-items: flex-end;
    top: 160px;
    left: 100px;
    @media screen and (min-width: 450px) {
      top: 230px;
    }
    @include media-breakpoint-up(md) {
      top: unset;
      bottom: 320px;
    }
    @include media-breakpoint-up(lg) {
      top: unset;
      bottom: -130px;
      left: -12px;
    }
  }
  &-square17 {
    position: absolute;
    top: 39rem;
    right: 12px;
  }
  &-square18 {
    top: 80px;
    left: 12px;
    @include media-breakpoint-up(lg) {
      top: 0;
    }
  }
  &-square19 {
    position: absolute;
    top: 70%;
    left: 126px;
  }
  &-square19m {
    position: absolute;
    top: 0;
    right: 12px;
    @media screen and (min-width: 375px) {
      right: 24px;
    }
  }
  &-square20 {
    position: absolute;
    top: 100px;
    left: 12px;
    @media screen and (min-width: 375px) {
      left: 24px;
    }
    @include media-breakpoint-up(lg) {
      top: 85%;
      left: 12px;
    }
  }
  &-square22 {
    position: absolute;
    top: 0;
    left: 12px;
    @media screen and (min-width: 375px) {
      left: 24px;
    }
    @include media-breakpoint-up(lg) {
      top: 85%;
      left: -196px;
    }
  }
  &-orbit1 {
    position: absolute;
    top: 60px;
    right: -73px;
    @include media-breakpoint-up(lg) {
      top: 20px;
      right: -69px;
      z-index: -1;
    }
    @include media-breakpoint-up(xl) {
      top: 30px;
    }
  }
  &-orbit2 {
    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -220px;
    }
  }
  &-orbit3 {
    position: absolute;
    top: 100%;
    right: -20px;
    @include media-breakpoint-up(lg) {
      bottom: unset;
      top: 60%;
      right: -33px;
      z-index: -1;
    }
  }
  &-orbit4 {
    position: absolute;
    top: 100px;
    z-index: -1;
    @include media-breakpoint-up(lg) {
      top: 100px;
    }
  }
  &-orbit5 {
    position: absolute;
    top: 70%;
  }
  &-orbit6 {
    @include media-breakpoint-up(lg) {
      position: absolute;
      bottom: 58px;
      left: -33px;
    }
  }
  &-orbit7 {
    position: absolute;
    top: 75px;
    right: -65px;
    @include media-breakpoint-up(lg) {
      top: 162px;
      right: -60px;
    }
  }
  &-orbit8 {
    position: absolute;
    bottom: 150px;
    left: -136px;
    z-index: -2;
  }
  &-orbit9 {
    position: absolute;
    top: 99%;
    right: -33px;
    @include media-breakpoint-up(lg) {
      top: 50%;
    }
  }
  &-orbit10 {
    position: absolute;
    top: 35%;
    left: -100px;
  }
  &-orbit11 {
    right: -50px;
    top: 260px;
    @include media-breakpoint-up(lg) {
      position: absolute;
      top: unset;
      bottom: 35%;
      right: 45px;
    }
  }
  &-orbit12 {
    position: absolute;
    bottom: -102px;
    left: -102px;
    z-index: -1;
  }
  &-orbit13 {
    position: absolute;
    top: 14.4rem;
    right: 6rem;
  }
  &-orbit14 {
    position: absolute;
    top: 9%;
    left: -17px;
    @include media-breakpoint-up(lg) {
      top: 25%;
      left: -102px;
    }
  }
  &-orbit15 {
    top: -4.4rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    @include media-breakpoint-up(lg) {
      top: -7rem;
    }
  }
  &-orbit16 {
    position: absolute;
    top: 20%;
    left: -102px;
  }
  &-orbit17 {
    position: absolute;
    top: -10%;
    right: 102px;
  }
  &-orbit18 {
    position: absolute;
    top: 70%;
    right: -169px;
  }
  &-orbit19 {
    top: 168px;
    left: -37px;
    position: absolute;
    @include media-breakpoint-up(lg) {
      top: unset;
      bottom: 62px;
      left: -30px;
    }
  }
  &-orbit20 {
    position: absolute;
    top: 59px;
    right: 0;
  }
  &-orbit21 {
    position: absolute;
    top: -80px;
    left: -169px;
    z-index: -1;
  }
  &-orbit22 {
    position: absolute;
    top: 125px;
    right: 0;
    z-index: -1;
  }
}
//ABOUT page end

// CONTACT page start
#contact {
  &-square1 {
    left: -216px;
    top: 50%;
  }
  &-square2 {
    position: absolute;
    right: 12px;
    top: -200px;
  }
  &-square3 {
    bottom: -10%;
  }
  &-square4 {
    bottom: 80%;
  }
  &-square5 {
    bottom: 0;
    right: 0;
    @include media-breakpoint-up(lg) {
      bottom: 40%;
    }
  }
  &-square6 {
    top: 0;
    right: 12px;
  }
  &-square7 {
    position: absolute;
    top: 20%;
    right: -216px;
  }
  &-orbit1 {
    position: absolute;
    left: 96px;
    top: 38rem;
  }
  &-orbit2 {
    position: absolute;
    bottom: 10px;
    right: -20px;
    @include media-breakpoint-up(lg) {
      bottom: -45px;
      right: -30px;
    }
  }
  &-orbit3 {
    position: absolute;
    bottom: -76px;
    right: 40px;
    z-index: -1;
    @include media-breakpoint-up(lg) {
      bottom: unset;
      top: 45%;
      right: unset;
      left: 32%;
    }
  }
  &-orbit4 {
    position: absolute;
    top: 0;
    left: 20px;
  }
}
// CONTACT page end

// DEVELOPERS page start
#dev {
  &-square {
    &1 {
      right: 24px;
      top: -117px;
      z-index: 2;
      @include media-breakpoint-up(lg) {
        left: -102px;
        right: unset;
        top: -50%;
        z-index: -1;
      }
    }
    &2 {
      @include media-breakpoint-up(lg) {
        left: -216px;
        top: 50%;
      }
    }
    &3 {
      @include media-breakpoint-up(lg) {
        left: 12px;
        top: 60%;
      }
    }
    &4 {
      @include media-breakpoint-up(lg) {
        right: -114px;
        top: 10%;
      }
    }
    &5 {
      z-index: -2;
      @include media-breakpoint-up(lg) {
        bottom: 0;
        right: 12px;
        z-index: 2;
      }
    }
    &6 {
      @include media-breakpoint-up(lg) {
        left: -102px;
        top: 20%;
      }
    }
    &7 {
      top: 148px;
      z-index: -2;
      @include media-breakpoint-up(md) {
        right: 170px;
        z-index: -1;
      }
      @include media-breakpoint-up(lg) {
        left: -102px;
        top: unset;
        bottom: 1rem;
        z-index: 0;
      }
    }
    &8 {
      @include media-breakpoint-up(lg) {
        right: -102px;
        top: 60%;
      }
    }
    &9 {
      @include media-breakpoint-up(lg) {
        right: -216px;
        top: 12%;
      }
    }
    &10 {
      position: absolute;
      @include media-breakpoint-up(lg) {
        left: -216px;
        top: 50%;
      }
    }
    &11 {
      right: 24px;
      bottom: 80px;
      z-index: 2;
      @include media-breakpoint-up(lg) {
        bottom: 100px;
        right: -102px;
      }
    }
    &12 {
      @include media-breakpoint-up(lg) {
        right: -216px;
        top: 10%;
      }
    }
    &13 {
      top: 18rem;
      z-index: 2;
      @include media-breakpoint-up(md) {
        right: 12px;
        top: 39rem;
      }
      @include media-breakpoint-up(lg) {
        left: -102px;
        top: unset;
        bottom: -2rem;
        z-index: 2;
      }
    }
    &14 {
      left: -74px;
      top: 64px;
      @include media-breakpoint-up(lg) {
        left: -102px;
        top: 40%;
      }
    }
    &15 {
      @include media-breakpoint-up(lg) {
        right: -102px;
        top: 60%;
      }
    }
    &16 {
      @include media-breakpoint-up(lg) {
        right: -216px;
        top: 12%;
      }
    }
    &17 {
      @include media-breakpoint-up(lg) {
        left: -216px;
        bottom: -12%;
      }
    }
    &18 {
      @include media-breakpoint-up(lg) {
        right: -216px;
        top: 12%;
      }
    }
    &19 {
      @include media-breakpoint-up(lg) {
        left: 12px;
        top: 0;
      }
    }
    &20 {
      @include media-breakpoint-up(lg) {
        right: -102px;
        top: 10%;
      }
    }
  }
}
// DEVELOPERS page end

// PODCAST page start
#podcast {
  &-square {
    &1 {
      left: -102px;
      right: unset;
      top: 0;
    }
    &2 {
      @include media-breakpoint-up(lg) {
        right: -102px;
        top: 5rem;
      }
    }
    &3 {
      @include media-breakpoint-up(lg) {
        right: -102px;
        top: 6.4rem;
      }
    }
    &4 {
      z-index: -1;
      @include media-breakpoint-up(lg) {
        left: -102px;
        top: 20%;
      }
    }
  }
  &-orbit {
    &1 {
      z-index: 2;
      right: -24rem;
      top: 7rem;
    }
  }
}
// PODCAST page end

// STARTUP page start
#startup {
  &-square {
    &1 {
      top: 120px;
      z-index: 0;
      position: absolute;
      @include media-breakpoint-up(xl) {
        left: -102px;
        top: unset;
        bottom: 6rem;
      }
    }
    &2 {
      @include media-breakpoint-up(lg) {
        left: 12px;
        top: 60%;
      }
    }
    &3 {
      @include media-breakpoint-up(lg) {
        right: -102px;
        top: 0;
      }
    }
    &4 {
      top: 0;
      @include media-breakpoint-up(lg) {
        position: absolute;
        left: -102px;
        top: -30%;
      }
    }
    &5 {
      @include media-breakpoint-up(lg) {
        right: -204px;
        top: 20%;
      }
    }
    &6 {
      left: 24px;
      z-index: 3;
      top: 115px;
      position: absolute;
      z-index: 3;
      @include media-breakpoint-up(lg) {
        left: unset;
        right: -102px;
        top: unset;
        bottom: 0;
      }
    }
    &7 {
      @include media-breakpoint-up(lg) {
        left: 12px;
        top: 10%;
        z-index: -1;
      }
    }
    &8 {
      position: absolute;
      left: 12px;
      top: 45%;
      z-index: 3;
    }
    &9 {
      @include media-breakpoint-up(lg) {
        position: absolute;
        left: -216px;
        top: 20%;
        z-index: 3;
      }
    }
    &10 {
      @include media-breakpoint-up(lg) {
        position: absolute;
        left: 12px;
        top: 50%;
        z-index: -1;
      }
    }
    &11 {
      @include media-breakpoint-up(lg) {
        right: -204px;
        top: 50%;
      }
    }
    &12 {
      @include media-breakpoint-up(lg) {
        left: -102px;
        top: 50%;
      }
    }
    &13 {
      @include media-breakpoint-up(lg) {
        left: 12px;
        bottom: -5rem;
        z-index: -1;
      }
    }
    &14 {
      top: -777px;
      z-index: 3;
      position: absolute;
      left: 24px;
      @include media-breakpoint-up(lg) {
        left: 12px;
        bottom: 20%;
        top: unset;
      }
    }
    &15 {
      @include media-breakpoint-up(lg) {
        right: -216px;
        top: 20%;
      }
    }
    &16 {
      @include media-breakpoint-up(lg) {
        right: -102px;
        bottom: 0;
      }
    }
    &17 {
      @include media-breakpoint-up(lg) {
        right: -204px;
        top: 10%;
      }
    }
    &18 {
      top: 120px;
      left: 24px;
      position: absolute;
      z-index: 4;
      @include media-breakpoint-up(lg) {
        left: -102px;
        top: 30%;
      }
    }
    &19 {
      @include media-breakpoint-up(lg) {
        position: absolute;
        left: 12px;
        top: 40%;
      }
    }
    &20 {
      @include media-breakpoint-up(lg) {
        position: absolute;
        z-index: 5;
        left: -102px;
        bottom: 10%;
      }
    }
    &21 {
      @include media-breakpoint-up(lg) {
        position: absolute;
        z-index: 5;
        right: -102px;
        bottom: 10%;
      }
    }
  }
}
#startup {
  &-orbit1 {
    position: absolute;
    right: -102px;
    top: -250px;
    z-index: -1;
    @include media-breakpoint-up(lg) {
      top: -102px;
    }
  }
  &-orbit2 {
    left: calc(100% - 45px);
    position: absolute;
    top: 150px;
    transform: translateY(-50%);
    z-index: 2;
    @include media-breakpoint-up(lg) {
      left: calc(50% - 45px);
      top: 120px;
    }
  }
  &-orbit3 {
    position: absolute;
    right: -20px;
    top: -35px;
    z-index: 3;
    @include media-breakpoint-up(md) {
      right: -92px;
      top: -102px;
    }
  }
  &-orbit4 {
    position: absolute;
    right: -50px;
    bottom: 85px;
    z-index: 4;
  }
  &-orbit5 {
    position: absolute;
    left: -158px;
    top: 25%;
    z-index: 3;
  }
  &-orbit6 {
    position: absolute;
    right: -60px;
    bottom: 52px;
    z-index: 3;
    @include media-breakpoint-up(lg) {
      bottom: 15px;
    }
    img {
      width: 138px;
      height: 138px;
      @include media-breakpoint-up(lg) {
        width: unset;
        height: unset;
        right: -92px;
      }
    }
  }
  &-orbit7 {
    position: absolute;
    right: -140px;
    top: -152px;
    z-index: 3;
  }
  &-orbit8 {
    position: absolute;
    right: -50px;
    bottom: 85px;
    z-index: 3;
  }
  &-orbit9 {
    position: absolute;
    right: -102px;
    top: 185px;
  }
  &-orbit10 {
    position: absolute;
    right: -30px;
    z-index: 3;
    top: 90px;
    @include media-breakpoint-up(lg) {
      top: 298px;
    }
  }
  &-orbit11 {
    position: absolute;
    right: -60px;
    bottom: 8.5rem;
    z-index: 5;
    @include media-breakpoint-up(lg) {
      right: 30px;
    }
  }
}
// STARTUP page end

// TEAM page start
#team {
  &-square1 {
    position: absolute;
    top: 30rem;
    left: -216px;
  }
  &-square2 {
    position: absolute;
    top: 60rem;
    left: -12px;
  }
  &-square3 {
    position: absolute;
    top: -16rem;
    left: -12px;
  }
  &-square4 {
    @include media-breakpoint-up(lg) {
      right: -102px;
      top: 12rem;
    }
  }
  &-square5 {
    position: absolute;
    top: 30rem;
    left: -216px;
  }
  &-square6 {
    @include media-breakpoint-up(lg) {
      right: -102px;
      top: 25rem;
    }
  }
  &-square7 {
    @include media-breakpoint-up(lg) {
      right: -216px;
      bottom: 30rem;
    }
  }
  &-square8 {
    @include media-breakpoint-up(lg) {
      right: 12px;
      top: 85rem;
    }
  }
  &-square9 {
    @include media-breakpoint-up(lg) {
      left: 12px;
      top: 120rem;
    }
  }
  &-orbit1 {
    left: 12px;
    top: 0;
  }
  &-orbit2 {
    left: -31px;
    top: 13rem;
    z-index: 2;
    @include media-breakpoint-up(lg) {
      left: -102px;
      top: 18rem;
    }
  }
  &-orbit3 {
    right: -126px;
    top: 6.4rem;
  }
  &-orbit4 {
    right: 45px;
    top: 22rem;
    @include media-breakpoint-up(lg) {
      top: 84rem;
    }
  }
}
// TEAM page end

// TERMS page start
#terms {
  &-square1 {
    top: 40%;
    right: 12px;
  }
  &-square2 {
    top: 8%;
    position: absolute;
    right: 12px;
  }
  &-square3 {
    top: 8%;
    left: 12px;
  }
  &-orbit1 {
    top: 250px;
    left: -220px;
  }
  &-orbit2 {
    top: 6%;
    left: 0;
  }
  &-orbit3 {
    top: 0;
    right: -50px;
  }
}
// TERMS page end

// PRIVACY page end
#privacy {
  &-square1 {
    top: 40%;
    right: 12px;
  }
  &-square2 {
    top: 5%;
    position: absolute;
    right: 12px;
  }
  &-square3 {
    top: 6%;
    left: 12px;
  }
  &-square4 {
    top: 20%;
    left: 12px;
  }
  &-orbit1 {
    top: 6%;
    left: 0;
  }
  &-orbit2 {
    top: 35%;
    right: 170px;
  }
  &-orbit3 {
    top: 25%;
    left: 117px;
  }
  &-orbit4 {
    top: 0;
    left: -60px;
  }
}
// PRIVACY page end

// ERROR page start
#error {
  &-orbit1 {
    position: absolute;
    left: -130px;
    top: 3rem;
  }
  &-orbit2 {
    position: absolute;
    right: -45px;
    top: 60%;
  }
  &-orbit3 {
    position: absolute;
    right: -80px;
    top: 75%;
  }
  &-square1 {
    position: absolute;
    top: 30rem;
    left: -216px;
  }
  &-square2 {
    position: absolute;
    top: 15rem;
    left: -102px;
  }
  &-square3 {
    position: absolute;
    bottom: 10%;
    right: -102px;
  }
  &-square4 {
    position: absolute;
    top: 10%;
    right: -216px;
  }
}

// ERROR page end
