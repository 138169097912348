.page-developers {
  h2.section__title {
    @include media-breakpoint-down(lg) {
      margin-top: 0;
    }
  }
  .section-opinions {
    &:before {
      display: none;
    }
  }
}

.devs-hero {
  .hero-title {
    margin-bottom: 3.2rem;
  }
  .section__subtitle--small {
    @include media-breakpoint-down(md) {
      font-size: 1.8rem;
    }
  }
}

.devs-hero,
.devs-difference,
.devs-magic {
  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
  }
  @media screen and (min-width: 1500px) {
    margin-bottom: 19.6rem;
  }
}

.devs-difference {
  .section__title--small {
    br {
      display: none;
      @media screen and (min-width: 1560px) {
        display: inline;
      }
    }
  }
}

#dev {
  &-orbit1 {
    position: absolute;
    top: 0;
    left: -73px;
    @include media-breakpoint-up(lg) {
      top: 30px;
      left: -69px;
      z-index: -1;
    }
    @include media-breakpoint-up(xl) {
      top: 69px;
    }
  }
  &-orbit2 {
    position: absolute;
    right: -60px;
    top: 50px;
  }
  &-orbit3 {
    left: -65px;
    top: -15px;
    position: absolute;
    @include media-breakpoint-up(sm) {
      left: 65px;
    }
    @include media-breakpoint-up(lg) {
      left: -102px;
      top: -70px;
    }
  }
  &-orbit4 {
    position: absolute;
    right: -60px;
    bottom: 100px;
  }
  &-orbit5 {
    position: absolute;
    left: -102px;
    top: 40%;
    z-index: -1;
    @media screen and (min-width: 1500px) {
      left: 0;
    }
  }
  &-orbit6 {
    position: absolute;
    right: 6rem;
    bottom: 2rem;
    @include media-breakpoint-up(lg) {
      bottom: 8rem;
    }
  }
  &-orbit7 {
    left: -65px;
    top: -15px;
    position: absolute;
    @include media-breakpoint-up(sm) {
      left: 65px;
    }
    @include media-breakpoint-up(lg) {
      left: -102px;
      top: 50px;
    }
  }
  &-orbit8 {
    position: absolute;
    right: -20px;
    top: 12.1rem;
    top: -205px;
    z-index: -1;
    @include media-breakpoint-up(lg) {
      top: 75%;
      right: -20px;
    }
  }
  &-orbit9 {
    left: -36px;
    top: -54px;
    position: absolute;
    @include media-breakpoint-up(md) {
      left: 0;
      top: -102px;
    }
  }
  &-orbit10 {
    position: absolute;
    right: -70px;
    top: 60%;
    z-index: -1;
  }
  &-orbit11 {
    position: absolute;
    right: -33px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  }
  &-orbit12 {
    position: absolute;
    bottom: -45px;
    left: -45px;
    z-index: -1;
  }
}

.dots-way {
  &-1 {
    width: calc(100% - 12px);
    max-width: calc(100% - 12px);
    position: absolute;
    bottom: -12.8rem;
    left: 12px;
  }
  &-2 {
    width: calc(100% + 12px);
    position: absolute;
    bottom: -12.8rem;
    left: 12px;
  }
  &-3 {
    width: calc(100% - 12px);
    max-width: calc(100% - 12px);
    position: absolute;
    bottom: -12.8rem;
    left: 12px;
  }
  &-4 {
    width: calc(100% + 57px);
    max-width: calc(100% + 57px);
    position: absolute;
    bottom: -12.8rem;
    left: 0;
  }
  &-5 {
    width: calc(100% + 36px);
    max-width: calc(100% + 36px);
    position: absolute;
    bottom: -12.8rem;
    left: -12px;
  }
  &-1,
  &-2,
  &-3,
  &-4,
  &-5 {
    display: none;
    z-index: 6;
    @media screen and (min-width: 1500px) {
      display: block;
    }
  }
}

.icon-list {
  margin-top: 3.2rem;
  margin-bottom: 3.2rem;
  &__item {
    padding: 1.1rem 1.6rem 1.1rem 4.8rem;
    background-color: white;
    border-radius: 48px 10px 10px 48px;
    box-shadow: $box-shadow;
    margin-bottom: 2.4rem;
    font-size: 1.8rem;
    font-weight: 600;
    position: relative;
    text-align: left;
    display: inline-flex;
    align-items: center;
    &--letter {
      margin-right: 2.4rem;
      white-space: nowrap;
      .icon-list__icon {
        background-color: $light3;
      }
    }
    &--diamant {
      .icon-list__icon {
        background-color: $light2;
      }
    }
    &--dollar {
      white-space: nowrap;
      .icon-list__icon {
        background-color: $light1;
      }
    }
  }
  &__icon {
    position: absolute;
    display: block;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &--podcast {
    margin-bottom: 0;
    padding-left: 0;
    @include media-breakpoint-up(md) {
      margin-top: 6.4rem;
    }
    .icon-list__item {
      margin-left: 12px;
      @include media-breakpoint-up(md) {
        margin-right: 12px;
      }
      &--trends {
        .icon-list__icon {
          background-color: #ebd8d8;
        }
      }
      &--observations {
        .icon-list__icon {
          background-color: $light3;
        }
      }
      &--advice {
        .icon-list__icon {
          background-color: $light2;
        }
      }
      &--challenges {
        .icon-list__icon {
          background-color: $light1;
        }
      }
    }
  }
}

.fanclub-list {
  z-index: 5;
  top: 12.8rem;
  & > div {
    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }
  @include media-breakpoint-up(lg) {
    position: absolute;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
  &__right {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(lg) {
      align-items: flex-end;
    }
  }
  .text-box {
    @include media-breakpoint-up(lg) {
      max-width: 31.8rem;
    }
  }
  .box-inner {
    &-l {
      @include media-breakpoint-up(lg) {
        margin-left: 6.4rem;
        margin-top: 2.4rem;
      }
    }
    &-r {
      @include media-breakpoint-up(lg) {
        margin-right: 6.4rem;
        margin-top: 2.4rem;
      }
    }
  }
}

.separator-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  width: 8px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3.2rem;
  margin-bottom: 3.2rem;
  @include media-breakpoint-up(md) {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem;
  }
  .dot {
    border-radius: 50%;
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
    &--big {
      width: 8px;
      height: 8px;
    }
    &--small {
      width: 4px;
      height: 4px;
    }
  }
  &--m {
    display: none;
    @include media-breakpoint-up(g) {
      display: flex;
    }
    @media screen and (min-width: 1500px) {
      display: none;
    }
  }
}

.devs-information {
  .section__text {
    p {
      font-weight: 600;
    }
    p.section__subtitle--small {
      display: inline-block;
      font-weight: bold;
      margin-bottom: 1.6rem;
      @include media-breakpoint-down(md) {
        font-size: 1.8rem;
      }
    }
  }
  .section__title--big {
    @include media-breakpoint-down(lg) {
      margin-bottom: 4rem;
    }
  }
}

.shh {
  display: inline-block;
  color: white;
  background-color: $lightblue;
  padding: 9px;
  border-radius: 8px;
  font-weight: bold;
  text-align: center;
  font-size: 2.4rem;
  position: absolute;
  font-size: 1.6rem;
  bottom: 126%;
  right: -1.2rem;
  @media screen and (min-width: 375px) {
    bottom: 110%;
    right: -5.2rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 2.4rem;
    right: -6.2rem;
  }
  &:before {
    content: "";
    width: 0;
    height: 0;
    border: 0 solid transparent;
    border-bottom-width: 10px;
    border-top-width: 0px;
    border-left: 12px solid $lightblue;
    position: absolute;
    left: 8px;
    bottom: -7px;
  }
}
