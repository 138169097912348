footer {
  margin-top: 1.4rem;
  padding-bottom: 3.2rem;
  @include media-breakpoint-up(md) {
    margin-top: 8rem;
    padding-bottom: 5.4rem;
  }
  @include media-breakpoint-up(lg) {
    padding-bottom: 11.8rem;
    margin-top: 11.5rem;
  }
  .nav-wrapper {
    margin-bottom: 5.3rem;
    @include media-breakpoint-up(lg) {
      margin-bottom: 8.4rem;
    }
    .btn {
      display: inline-block;
    }
    .nav-list {
      margin-left: 6.4rem;
      height: 48px;
      a {
        background-image: none;
      }
      li:first-of-type {
        a {
          margin-left: 0;
        }
      }
    }
  }
  .address-box {
    font-size: 1.6rem;
    line-height: 1.625;
    font-weight: 500;
    @include media-breakpoint-up(lg) {
      padding-left: 15px;
    }
    .address {
      margin-bottom: 0;
      font-style: normal;
      width: calc(100% - 73px);
    }
    .icon-wrapper {
      margin-right: 2.5rem;
      img {
        width: 16px;
        height: 16px;
      }
    }
    p {
      margin-bottom: 0;
    }
  }
  .copyright {
    margin-top: 3.2rem;
    font-size: 1.6rem;
    line-height: 1.625;
    font-weight: 500;
    @include media-breakpoint-up(lg) {
      margin-top: 6.4rem;
    }
    ul {
      padding-left: 0;
      list-style-type: none;
      li {
        padding-left: 0.6rem;
      }
    }
  }
}

.border-row {
  position: relative;
  &:before {
    content: "";
    top: -6rem;
    left: 24px;
    right: 24px;
    height: 2px;
    width: calc(100%- 48px);
    position: absolute;
    display: block;
    @include media-breakpoint-up(lg) {
      top: -11.5rem;
      width: 100%;
      left: 0;
      right: 0;
    }
  }
}

.recaptcha-info {
  font-size: 1.1rem;
  color: $text-color;
}

.page-terms footer .text-lighter {
  margin-top: 0;
}
