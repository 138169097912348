.contact {
  &-hero {
    position: relative;
    margin-bottom: 6.2rem;
    @include media-breakpoint-up(lg) {
      margin-bottom: 12.8rem;
    }
    .hero-title {
      @include media-breakpoint-up(lg) {
        margin-bottom: 6.4rem;
      }
    }
    &__foto {
      @include media-breakpoint-down(lg) {
        margin-bottom: 3.2rem;
      }
      img {
        width: 100%;
        max-width: 598px;
        max-height: 400px;
        object-fit: cover;
        border-radius: 20px 100px 20px 20px;
      }
    }
    .section__subtitle {
      margin-bottom: 3.2rem;
      @include media-breakpoint-up(lg) {
        margin-bottom: 5.3rem;
      }
    }
  }
}

.person-box {
  text-align: center;
  box-shadow: $box-shadow;
  border-radius: 20px;
  background-color: white;
  padding: 7.2rem 2.4rem 2.4rem 2.4rem;
  @include media-breakpoint-up(md) {
    padding: 7.2rem 1rem 4.8rem 1rem;
  }
  &__img-wrapper {
    position: absolute;
    width: 80px;
    height: 80px;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }
  &__foto {
    width: 100%;
    height: 100%;
    object-fit: cover;
    clip-path: circle(50% at 50% 50%);
    box-shadow: $box-shadow;
  }
  &__name {
    font-size: 2.4rem;
    font-weight: bold;
    color: $header-color;
    display: block;
  }
  &__occupation {
    font-size: 1.2rem;
    color: $text-color;
    font-weight: bold;
    margin-bottom: 1.6rem;
    display: inline-block;
  }
  &__address {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    a {
      color: $text-color;
      font-size: 1.6rem;
      line-height: 1.5;
      font-weight: 600;
    }
  }
  .btn {
    margin-top: 3.2rem;
  }
  .calendly-mark {
    position: absolute;
    background-image: url(../assets/images/calendly-logo.png);
    top: -4px;
    right: -4px;
    width: 64px;
    height: 64px;
    z-index: 2;
    background-position: top right;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.contact-form-subpage {
  padding-left: 24px;
  padding-right: 24px;
  @include media-breakpoint-up(lg) {
    padding-left: 6.4rem;
  }
  input[type="text"],
  input[type="email"],
  textarea {
    max-width: 669px;
  }
}

.contact-box {
  width: 100%;
  @media screen and (min-width: 375px) {
    width: 32.2rem;
    max-width: 32.2rem;
  }
}

.form-section {
  padding-bottom: 0;
  .quote-wrapper {
    @include media-breakpoint-down(lg) {
      height: calc(100% + 78px);
    }
  }
  .section__title {
    margin-bottom: 12rem;
  }
  .contact-box {
    @include media-breakpoint-down(md) {
      margin-top: 13.8rem;
      margin-left: auto;
      margin-right: auto;
    }
    @include media-breakpoint-up(lg) {
      margin-top: 4.3rem;
    }
  }
}

.contact-box {
  position: relative;
  &:before {
    content: "";
    top: 0;
    width: calc(100% - 96px);
    left: 48px;
    height: 2px;
    position: absolute;
    display: block;
    background-color: $blue;
    z-index: 2;
  }
}

.bordered-b {
  padding-bottom: 10rem;
  @include media-breakpoint-up(lg) {
    padding-bottom: 20rem;
    border-bottom: 1px solid $light3;
  }
}

.section-welcome {
  .contact-box {
    width: 100%;
    margin-top: 3.2rem;
    margin-left: auto;
    margin-right: auto;
    @media screen and (min-width: 375px) {
      width: 32.2rem;
      max-width: 32.2rem;
    }
    @include media-breakpoint-up(lg) {
      margin-left: unset;
      margin-right: unset;
    }
  }
  .card-box {
    width: 100%;
    margin-top: 3.2rem;
    margin-left: auto;
    margin-right: auto;
    @media screen and (min-width: 375px) {
      width: 32.2rem;
    }
    @include media-breakpoint-up(lg) {
      margin-left: unset;
      margin-right: unset;
    }
    @include media-breakpoint-up(md) {
      min-height: 32.5rem;
      padding: 2.8rem;
    }
    @include media-breakpoint-up(lg) {
      width: auto;
    }
    @media screen and (min-width: 1320px) {
      padding: 3rem;
    }
    @media screen and (min-width: 1560px) {
      padding: 4.8rem;
    }
    &__desc {
      width: 100%;
      max-width: 100%;
    }
  }
}
