.page-team {
  .quote {
    @include media-breakpoint-down(sm) {
      padding-right: 0;
    }
    @include media-breakpoint-down(xl) {
      padding-top: 2rem;
    }
    .section__title {
      margin-top: -3px;
      margin-bottom: 2rem;
      @include media-breakpoint-up(sm) {
        margin-bottom: 4rem;
      }
      @include media-breakpoint-up(md) {
        margin-top: -1rem;
        margin-top: -2rem;
      }
    }
  }
  .section-opinions {
    &:before {
      display: none;
    }
  }
}

.team-opinions {
  margin-top: 2.8rem;
  margin-bottom: 4.4rem;
  @include media-breakpoint-up(md) {
    margin-top: 12.8rem;
    margin-bottom: 12.8rem;
  }
}

.team-brian {
  margin-top: 3.2rem;
  @include media-breakpoint-up(lg) {
    margin-top: 12.8rem;
  }
}

.team-soo {
  margin-top: 25.6rem;
}

.team-brian {
  .line-attach {
    &__line {
      left: -2.4rem;
      @include media-breakpoint-up(lg) {
        left: -6.5rem;
      }
    }
  }
  .quote {
    @include media-breakpoint-down(lg) {
      margin-bottom: 6.4rem;
    }
  }
}

.team-jonathan {
  .line-attach {
    &__line {
      left: -2.4rem;
      @include media-breakpoint-up(lg) {
        left: unset;
        right: -6.5rem;
      }
    }
  }
  .section__title {
    br {
      display: inline;
      @media screen and (min-width: 410px) {
        display: none;
      }
    }
  }
}

.line-attach {
  position: relative;
  margin-bottom: 0;
  padding-bottom: 3.2rem;
  @include media-breakpoint-up(sm) {
    padding-bottom: 5.6rem;
  }
  @include media-breakpoint-up(md) {
    padding-bottom: 6.4rem;
  }

  &__line {
    display: block;
    position: absolute;
    top: 8px;
    width: 2px;
    background-color: $light2;
    z-index: 1;
    &--invisible {
      background-color: transparent;
    }
  }
  &__line-blue {
    display: block;
    position: absolute;
    top: 8px;
    width: 2px;
    background-color: $blue;
    z-index: 2;
  }
  &__dot {
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: relative;
    left: -3px;
    bottom: 0;
    background-color: $light2;
  }
}

.border-growing-left {
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: -2.4rem;
    top: 8px;
    width: 2px;
    height: 100%;
    background-color: $light2;
    z-index: -2;
    @include media-breakpoint-up(lg) {
      left: -6.5rem;
    }
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    left: -2.8rem;
    bottom: -16px;
    background-color: $light2;
    @include media-breakpoint-up(lg) {
      left: -6.8rem;
    }
  }
}
.border-growing-right {
  position: relative;
  margin-top: 2rem;
  position: relative;
  .line-attach {
    &:after {
      left: -2.8rem;
      bottom: -16px;
      @include media-breakpoint-up(lg) {
        left: calc(100% + 6rem);
      }
    }
    &:first-of-type {
      @include media-breakpoint-down(md) {
        padding-bottom: 4.8rem;
      }
    }
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: -2.4rem;
    top: 8px;
    width: 2px;
    height: 100%;
    background-color: $light2;
    @include media-breakpoint-up(lg) {
      left: unset;
      right: -6.5rem;
    }
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    bottom: -16px;
    background-color: $light2;
    left: -2.8rem;
    border-radius: 50%;

    @include media-breakpoint-up(lg) {
      left: unset;
      right: -6.8rem;
    }
  }
}

h2.line-attach {
  padding-bottom: 9.6rem;
}

.about-box {
  display: block;
  position: relative;
  padding: 0 2.4rem;
  margin-bottom: 2.4rem;
  font-weight: 600;
  @include media-breakpoint-up(sm) {
    padding: 0 4rem;
    margin-bottom: 6.4rem;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    border-radius: 20px;
    width: 100%;
    height: calc(100% + 2.4rem * 2);
    top: -2.4rem;
    left: 0;
    z-index: -1;
    @include media-breakpoint-up(sm) {
      height: calc(100% + 4.8rem * 2);
      top: -4.8rem;
    }
  }
  &__triangle {
    position: relative;
    height: 100%;
    display: block;
    &:after {
      top: -49px;
      left: 2.4rem;
      border-top-width: 27px;
      border-bottom-width: 0;
      @include media-breakpoint-up(sm) {
        top: -74px;
      }
    }
    &:before {
      display: none;
    }
  }
  &--green {
    &:before {
      background-color: $light3;
    }
  }
  &--orange {
    &:before {
      background-color: $light1;
    }
  }
}

.role {
  color: #fff;
  border-radius: 5px;
  font-size: 1.2rem;
  padding: 7px 8px;
  background-color: $lightblue;
  position: absolute;
  top: -10px;
  left: calc(100% + 1.6rem);
  @include media-breakpoint-up(lg) {
    top: 0;
  }
}

.team {
  &-brian,
  &-jonathan {
    position: relative;
  }
  &-brian {
    @include media-breakpoint-up(lg) {
      margin-bottom: 25.6rem;
    }
  }
  &-jonathan {
    @include media-breakpoint-up(lg) {
      margin-bottom: 19.2rem;
    }
  }
}

.team-hero {
  .section__subtitle--small {
    @include media-breakpoint-down(md) {
      font-size: 1.8rem;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 1.6rem;
    }
  }
}

.sticky-photo {
  position: sticky;
  top: 80px;
}

.person-foto {
  width: 100%;
  height: auto;
  object-fit: cover;
  display: inline-block;
  border-radius: 20px;
  position: sticky;
  top: 20px;
  &--brian {
    margin-bottom: 3.5rem;
    height: 336px;
    max-width: 327px;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(md) {
      height: 540px;
      max-width: 100%;
    }
    @media screen and (min-width: 1920px) {
      height: 560px;
    }
  }
  &--jonathan {
    margin-bottom: 1.5rem;
    max-width: 327px;
    margin-left: auto;
    margin-right: auto;
    @include media-breakpoint-up(md) {
      height: 500px;
      max-width: 100%;
    }
    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
    @media screen and (min-width: 1920px) {
      height: 480px;
    }
  }
}
