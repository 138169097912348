.error-content {
  .btn {
    margin-top: 6.4rem;
  }
}

.section {
  &--error {
    .bordered-b {
      padding-bottom: 6.4rem;
    }
  }
}

.page-error {
  footer {
    margin-top: 9.6rem;
  }
}
