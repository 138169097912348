.page-startups {
  .section__title {
    &--big {
      margin-bottom: 3.2rem;
      @include media-breakpoint-up(lg) {
        margin-bottom: 6.4rem;
      }
    }
  }
  .section-opinions {
    &:before {
      display: none;
    }
  }
  .about-opinions {
    @include media-breakpoint-down(md) {
      margin-top: 3.2rem;
    }
  }
}

.startup-hero {
  @include media-breakpoint-up(lg) {
    margin-bottom: 6.4rem;
  }
  .section__subtitle--small {
    @include media-breakpoint-down(md) {
      font-size: 1.8rem;
    }
  }
}

.section-lead {
  @media screen and (min-width: 1500px) {
    margin-bottom: 19.6rem;
  }
}

.section-point {
  @media screen and (min-width: 1500px) {
    margin-bottom: 19.6rem;
  }
}

.section-busy {
  @media screen and (min-width: 1500px) {
    margin-bottom: 12.8rem;
  }
  .section__title--small {
    margin-bottom: 1.6rem;
    font-size: 2.4rem;
    @include media-breakpoint-up(lg) {
      font-size: 3.2rem;
    }
  }
  .section__subtitle--small {
    font-size: 1.8rem;
    @include media-breakpoint-up(lg) {
      font-size: 2.4rem;
    }
  }
}

.section-universe {
  @media screen and (min-width: 1500px) {
    margin-bottom: 19.6rem;
  }
  .section__title--big {
    margin-bottom: 1.6rem;
  }
  .text-box--checked {
    margin-top: 3.2rem;
    @include media-breakpoint-up(lg) {
      margin-top: 4.8rem;
    }
  }
}

.section-information {
  @media screen and (min-width: 1500px) {
    margin-bottom: 19.6rem;
  }
  .section__title {
    margin-bottom: 3.2rem;
  }
  .icon-list {
    margin-top: 0;
    &__item {
      margin-bottom: 0;
      &--code {
        margin-bottom: 2.4rem;
        @include media-breakpoint-up(lg) {
          margin-bottom: 0;
        }
        .icon-list__icon {
          background-color: $light3;
        }
      }
      &--resume {
        @include media-breakpoint-up(lg) {
          margin-left: 0.8rem;
        }
        .icon-list__icon {
          background-color: $light2;
        }
      }
      &--candidate {
        margin-bottom: 2.4rem;
        .icon-list__icon {
          background-color: #ebd8d8;
        }
      }
    }
  }
}
