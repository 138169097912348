.page-terms {
  .hero-title {
    margin-bottom: 3.2rem;
    @include media-breakpoint-up(lg) {
      margin-bottom: 6.4rem;
    }
  }
  .section__title--small {
    margin-bottom: 1.6rem;

    @include media-breakpoint-down(lg) {
      font-size: 2.4rem;
    }
  }
  .section__text p:not(:last-of-type) {
    margin-bottom: 3.2rem;
    @include media-breakpoint-up(lg) {
      margin-bottom: 6.4rem;
    }
  }
  .text-lighter {
    margin-top: 4.8rem;
  }
  .bordered-b--terms {
    padding-bottom: 11.2rem;
    padding-bottom: 4.8rem;
    margin-bottom: 6.4rem;
    border-bottom: 1px solid $light3;
    @include media-breakpoint-up(lg) {
      padding-bottom: 12.8rem;
      margin-bottom: 9.6rem;
    }
  }
  footer {
    margin-top: 0;
  }
}
