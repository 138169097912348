// HOME/JAVASCRIPT start
#home_ill {
  &_1 {
    max-width: calc(100% - 80px);
    margin-right: auto;
    background-color: $light1;
    border-radius: 100px 20px 20px 20px;
    margin-bottom: 6.4rem;
    @include media-breakpoint-up(lg) {
      max-width: 100%;
      margin-bottom: 0;
      margin-right: unset;
    }
    svg {
      width: calc(100% + 50px) !important;
      max-width: calc(100% + 50px) !important;
      transform: translateX(-10px) translate3d(0px, 0px, 0px) scale(1.03) !important;
      transform-origin: 3% 100%;
      max-height: unset !important;
      @include media-breakpoint-up(lg) {
        width: calc(100% + 120px) !important;
        max-width: calc(100% + 120px) !important;
        transform: translateX(-10px) translate3d(0px, 0px, 0px) scale(1.03) !important;
      }
      @include media-breakpoint-up(xl) {
        width: calc(100% + 120px) !important;
        max-width: calc(100% + 120px) !important;
        transform: translateX(-40px) translate3d(0px, 0px, 0px) scale(1.03) !important;
      }
    }
  }
  &_2 {
    max-width: 100%;
    margin-left: auto;
    margin-left: auto;
    background-color: $light2;
    border-radius: 20px 20px 20px 100px;
    margin-bottom: 6.4rem;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
    svg {
      width: calc(100% + 40px) !important;
      max-width: calc(100% + 40px) !important;
      transform: translateX(-17px) translateY(1px) scale(1.03)
        translate3d(0, 0, 0) !important;
      transform-origin: 3% 100%;
      @include media-breakpoint-up(lg) {
        width: calc(100% + 40px) !important;
        max-width: calc(100% + 40px) !important;
        transform: translateX(-70px) translateY(1px) translate3d(0, 0, 0)
          scale(1.3) !important;
        transform-origin: 30% 100%;
      }
      @include media-breakpoint-up(xl) {
        width: calc(100% + 60px) !important;
        max-width: calc(100% + 60px) !important;
        transform: translateX(-30px) scale(1.03) translateY(1px)
          translate3d(0px, 0px, 0px) !important;
      }
      transform-origin: 3% 100%;
    }
  }
  &_3 {
    width: calc(100% - 110px);
    background-color: #ebd8d8;
    border-radius: 20px 100px 20px 20px;
    margin-bottom: 6.4rem;
    transform: translateX(40px);
    @include media-breakpoint-up(lg) {
      width: 100%;
      transform: none;
      margin-bottom: 0;
    }
    svg {
      width: 100% !important;
      max-width: 100% !important;
      transform: translateX(-43px) translate3d(0, 0, 0) scale(1.1) !important;
      max-height: unset !important;
      bottom: 0;
      transform-origin: 10% 100%;
      z-index: 2;
      position: relative;
      @include media-breakpoint-up(lg) {
        width: 100% !important;
        max-width: 100% !important;
        transform: translateX(-70px) translate3d(0, 0, 0) scale(1.05) !important;
        transform-origin: 5% 100%;
      }
      @include media-breakpoint-up(xl) {
        width: calc(100% + 40px) !important;
        max-width: calc(100% + 40px) !important;
        transform: translateX(-70px) translate3d(0, 0, 0) scale(1.08) !important;
        transform-origin: 8% 100%;
      }
    }
  }
}

// HOME/JAVASCRIPT end
// ABOUT start
#about_ill {
  &_1 {
    margin-bottom: 10.6rem;
    width: calc(100% - 30px);
    transform: translateX(30px);
    background-color: $light2;
    border-radius: 20px 20px 100px 20px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      width: 100%;
      transform: none;
    }
    svg {
      width: calc(100% + 40px) !important;
      transform: translateX(-30px) translateY(30px) translate3d(0px, 0px, 0px) !important;
      max-height: unset !important;
      @include media-breakpoint-up(lg) {
        width: calc(100% + 85px) !important;
      }
      @include media-breakpoint-up(xl) {
        transform: translateX(-55px) translateY(40px) translate3d(0px, 0px, 0px) !important;
      }
    }
  }
  &_2 {
    margin-bottom: 13rem;
    padding-top: 23px;
    background-color: $light1;
    border-radius: 100px 20px 20px 20px;
    @include media-breakpoint-up(xl) {
      margin-bottom: 0;
      padding-top: 47px;
    }
    svg {
      transform: translateX(-12px) translate3d(0px, 0px, 0px) !important;
      width: calc(100% + 24px) !important;
      max-height: unset !important;
      @include media-breakpoint-up(md) {
        width: calc(100% + 80px) !important;
        transform: translateX(-40px) translate3d(0px, 0px, 0px) !important;
      }
    }
  }
  &_3 {
    margin-bottom: 8rem;
    padding-top: -5rem;
    background-color: $light3;
    border-radius: 20px 20px 100px 20px;
    @include media-breakpoint-up(md) {
      margin-top: 6.4rem;
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: 0;
    }
    svg {
      transform: translateX(20px) scale(1.1) translate3d(0px, 0px, 0px) !important;
      transform-origin: 10% 66%;
      width: 80% !important;
      max-width: 80% !important;
      height: 80% !important;
      max-height: 80% !important;
      @include media-breakpoint-up(lg) {
        transform: translateX(20px) scale(1.06) translate3d(0px, 0px, 0px) !important;
        transform-origin: 6% 33%;
        width: 80% !important;
        max-width: 80% !important;
      }
      @include media-breakpoint-up(xl) {
        transform: translateX(50px) scale(1.1) translate3d(0px, 0px, 0px) !important;
        transform-origin: 10% 68%;
        width: 80% !important;
        max-width: 80% !important;
      }
    }
  }
  &_4,
  &_4 {
    margin-bottom: 3.2rem;
    background-color: $light2;
    border-radius: 100px 20px 20px 20px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      padding-top: 2rem;
    }
    svg {
      max-height: unset !important;
      max-width: calc(100% + 80px) translate3d(0px, 0px, 0px) !important;
      transform: translateX(-5px) scale(1.05) translate3d(0px, 0px, 0px) !important;
      transform-origin: 5% 100%;
      @include media-breakpoint-up(lg) {
        transform: translateX(-28px) scale(1.05) translate3d(0px, 0px, 0px) !important;
      }
      @include media-breakpoint-up(xl) {
        max-width: calc(100% + 120px) !important;
        transform: translateX(-20px) scale(1.06) translate3d(0, 0, 0) !important;
        transform-origin: 6% 100%;
      }
    }
  }
  &_5 {
    margin-bottom: 9.5rem;
    background-color: $light1;
    border-radius: 20px 20px 100px 20px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
    svg {
      transform: translateX(0px) scale(1.05) translate3d(0px, 0px, 0px) !important;
      transform-origin: 5% 100%;
      max-width: 100% !important;
      max-height: unset !important;
      @include media-breakpoint-up(lg) {
        transform: translateX(15px) scale(1) translate3d(0px, 0px, 0px) !important;
        transform-origin: 100%;
        max-width: calc(100% + 40px) !important;
      }
      @include media-breakpoint-up(xl) {
        transform: translateX(15px) scale(1.05) translate3d(0px, 0px, 0px) !important;
        max-width: calc(100% + 60px) !important;
        transform-origin: 5% 100%;
      }
    }
  }
  &_6 {
    margin-bottom: 9.5rem;
    background-color: $light3;
    border-radius: 100px 20px 20px 20px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      padding-top: 6rem;
    }
    svg {
      max-width: 100% !important;
      max-height: unset !important;
      transform: translateX(-7px) scale(1.07) translate3d(0px, 0px, 0px) !important;
      transform-origin: 7% 100%;
      @include media-breakpoint-up(lg) {
        transform: translateX(-5px) scale(1.05) translate3d(0px, 0px, 0px) !important;
        transform-origin: 5% 100%;
        max-width: calc(100% + 40px) !important;
      }
      @include media-breakpoint-up(xl) {
        transform: translateX(-10px) scale(1.05) translate3d(0px, 0px, 0px) !important;
        transform-origin: 5% 100%;
        max-width: calc(100% + 30px) !important;
      }
    }
  }
}

// ABOUT end
// DEVELOPERS start
#developers_ill {
  &_1 {
    margin-bottom: 6.6rem;
    margin-top: 1rem;
    background-color: $light3;
    border-radius: 100px 20px 20px 20px;
    max-width: calc(100% - 40px);
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      max-width: 100%;
      transform: none;
    }
    svg {
      max-width: 90% !important;
      max-height: unset !important;
      transform: translateX(17px) translateY(18px) translate3d(0px, 0px, 0px) !important;
      @include media-breakpoint-up(lg) {
        max-width: calc(100% - 60px) !important;
        transform: translateX(30px) translateY(18px) translate3d(0px, 0px, 0px) !important;
      }
    }
  }
  &_2 {
    margin-bottom: 3.2rem;
    max-width: calc(100% - 40px);
    transform: translateX(40px);
    background-color: $light2;
    border-radius: 100px 20px 20px 20px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      max-width: 100%;
      transform: none;
    }
    svg {
      max-width: 100%;
      max-height: unset !important;
      transform: translateX(-8px) scale(1.08) translate3d(0px, 0px, 0px) !important;
      transform-origin: 8% 100%;
      position: relative;
      z-index: 4;
      @include media-breakpoint-up(lg) {
        width: calc(100% + 60px) !important;
        max-width: calc(100% + 60px) !important;
        transform: translateX(-30px) scale(1.03) translate3d(0px, 0px, 0px) !important;
        transform-origin: 3% 100%;
      }
      @include media-breakpoint-up(xl) {
        width: calc(100% + 70px) !important;
        max-width: calc(100% + 70px) !important;
        transform: translateX(-50px) scale(1.04) translate3d(0px, 0px, 0px) !important;
        transform-origin: 4% 100%;
      }
    }
  }
  &_3 {
    margin-bottom: 6rem;
    margin-top: 1rem;
    max-width: calc(100% - 50px);
    background-color: $light1;
    border-radius: 20px 20px 100px 20px;
    @include media-breakpoint-up(md) {
      margin-top: 6.4rem;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      max-width: 100%;
    }
    svg {
      max-width: 100%;
      max-height: unset !important;
      transform: translateX(-11px) translateY(-9px) scale(1.06)
        translate3d(0px, 0px, 0px) !important;
      @include media-breakpoint-up(md) {
        transform: translateX(-19px) translateY(-15px) scale(1.06)
          translate3d(0px, 0px, 0px) !important;
      }
      @include media-breakpoint-up(lg) {
        transform: translateX(-15px) translateY(-10px) scale(1.06)
          translate3d(0, 0, 0) !important;
      }
      @include media-breakpoint-up(xl) {
        transform: translateX(-22px) translateY(-10px) scale(1.06)
          translate3d(0, 0, 0) !important;
      }
    }
  }
  &_4 {
    margin-bottom: 1.6rem;
    padding-top: 6rem;
    max-width: calc(100% - 40px);
    background-color: $light3;
    border-radius: 20px 100px 20px 20px;
    transform: translateX(40px);
    @include media-breakpoint-up(md) {
      margin-bottom: 3.2rem;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      max-width: 100%;
      transform: none;
    }
    svg {
      max-width: 90% !important;
      max-height: unset !important;
      transform: translateX(15px) translate3d(0px, 0px, 0px) !important;
      @include media-breakpoint-up(md) {
        width: 100% !important;
        max-width: 100% !important;
      }
      @include media-breakpoint-up(lg) {
        width: calc(100% - 40px) !important;
        max-width: calc(100% - 40px) !important;
      }
    }
  }
  &_5 {
    margin-bottom: 3.2rem;
    overflow: hidden;
    background-color: $light2;
    border-radius: 20px 20px 100px 20px;
    @include media-breakpoint-up(md) {
      margin-bottom: 6.4rem;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
    svg {
      width: 100%;
      @include media-breakpoint-up(lg) {
        max-width: calc(100% + 60px) !important;
      }
    }
  }
}

// DEVELOPERS end
// STARTUPS start
#startup_ill {
  &_1 {
    max-width: calc(100% - 50px);
    margin-left: auto;
    margin-bottom: 4rem;
    margin-left: auto;
    padding-top: 4rem;
    background-color: $light2;
    border-radius: 20px 100px 20px 20px;
    @include media-breakpoint-up(md) {
      transform: none;
      width: 100%;
      z-index: 2;
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: 0;
    }
    svg {
      max-height: unset !important;
      transform: translateX(-30px) scale(1.05) translate3d(0px, 0px, 0px) !important;
      width: calc(100% + 30px) !important;
      position: relative;
      z-index: 4;
      @include media-breakpoint-up(md) {
        max-width: calc(100% + 60px) !important;
        transform: translateX(-30px) translateY(-8px) scale(1.05)
          translate3d(0px, 0px, 0px) !important;
      }
      @include media-breakpoint-up(lg) {
        max-width: calc(100% + 60px) !important;
        transform: translateX(-30px) translateY(-8px) scale(1.05)
          translate3d(0px, 0px, 0px) !important;
      }
    }
  }
  &_2 {
    width: calc(100% - 50px);
    z-index: -1;
    margin-bottom: 3.2rem;
    margin-left: auto;
    background-color: $light3;
    border-radius: 100px 20px 100px 20px;
    padding-top: 2rem;
    @include media-breakpoint-up(md) {
      width: 100%;
      z-index: 2;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
    svg {
      max-width: 100%;
      max-height: unset !important;
    }
  }
  &_3 {
    width: 100%;
    z-index: -1;
    margin-bottom: 3.2rem;
    padding-top: 3rem;
    background-color: $light1;
    border-radius: 100px 20px 20px 20px;
    @include media-breakpoint-up(md) {
      z-index: 2;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
    svg {
      max-width: 100% !important;
      max-height: unset !important;
      transform: translateX(13px) translateY(7px) scale(1.1)
        translate3d(0, 0, 0) !important;
    }
  }
  &_4 {
    width: calc(100% - 24px);
    z-index: 2;
    margin-bottom: 3.2rem;
    margin-left: auto;
    overflow: hidden;
    background-color: $light2;
    border-radius: 20px 20px 20px 100px;
    @include media-breakpoint-up(md) {
      width: 100%;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      margin-left: unset;
    }
    svg {
      max-width: 110% !important;
      max-height: unset !important;
    }
  }
  &_5 {
    width: calc(100% - 24px);
    z-index: -1;
    margin-bottom: 3.2rem;
    margin-left: auto;
    background-color: $light3;
    border-radius: 20px 100px 20px 20px;
    @include media-breakpoint-up(md) {
      width: 100%;
      z-index: 2;
      margin-left: unset;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
    svg {
      max-width: calc(100% - 30px) !important;
      max-height: unset !important;
      transform: translateX(30px) translateY(-6px) scale(1.05)
        translate3d(0px, 0px, 0px) !important;
      @include media-breakpoint-up(md) {
        max-width: calc(100% - 30px) !important;
        transform: translateX(36px) translateY(-27px) scale(1.1)
          translate3d(0, 0, 0) !important;
      }
    }
  }
}

// STARTUPS end
// PODCAST start
#podcast_ill {
  &_1 {
    margin-bottom: 3.2rem;
    position: relative;
    overflow: hidden;
    @include media-breakpoint-up(md) {
      height: 400px;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      height: 560px;
    }
    svg {
      position: absolute;
      bottom: 0;
      width: 100%;
      max-width: 100% !important;
      height: 100%;
      max-height: unset !important;
    }
  }
}
