.page-podcast {
  .card-box {
    @media screen and (min-width: 1500px) {
      padding: 5.6rem 4.8rem 4.8rem 4.8rem;
    }
    .section__intro {
      margin-top: 3.2rem;
    }
    .top-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
  .card-box--col {
    flex-direction: column;
    align-items: flex-start;
    .card-box__icon-wrap {
      margin-right: 2.4rem;
      margin-bottom: 0;
    }
  }
  .card-box {
    @include media-breakpoint-up(lg) {
      padding: 3.8rem;
    }
    @media screen and (min-width: 1560px) {
      padding: 4.5rem;
    }
    @media screen and (min-width: 1920px) {
      padding: 4.8rem;
    }
    .card-box__title--big {
      @include media-breakpoint-up(lg) {
        font-size: 2.2rem;
      }
      @media screen and (min-width: 1560px) {
        font-size: 2.4rem;
      }
    }
  }
  .card-box__desc {
    width: 100%;
    text-align: left;
  }
  .hero-title {
    margin-top: 3.2rem;
  }
  .section__subtitle--small {
    font-size: 1.8rem;
    @include media-breakpoint-up(md) {
      font-size: 2.4rem;
    }
  }
  .section-welcome {
    .section__title {
      margin-bottom: 3.2rem;
      @include media-breakpoint-up(lg) {
        margin-bottom: 6.4rem;
      }
    }
  }
  footer {
    margin-top: 0;
  }
}

.podcast-subtitle {
  position: relative;
  display: inline-block;
}

.podcast-links {
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: column;
  margin-top: 3.2rem;
  &__list {
    margin: 0;
    padding-left: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    @media screen and (min-width: 500px) {
      flex-direction: row;
    }
    @include media-breakpoint-up(md) {
      margin: 3.2rem 0 0 0;
      margin-bottom: 6.4rem;
    }

    li {
      &:nth-of-type(1) {
        a {
          @media screen and (min-width: 1200px) {
            border-radius: 30px 0 0 30px;
            border-right: none;
          }
        }
      }
      &:last-of-type {
        a {
          @media screen and (min-width: 1200px) {
            border-radius: 0 30px 30px 0;
            border-right: 1px solid $light3;
          }
        }
      }

      a {
        border: 1px solid $light3;
        border-radius: 30px;
        margin: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.7rem 2.4rem;
        color: $text-color;
        transition: $transition-time;
        @media screen and (min-width: 1200px) {
          margin: 0;
          border-right: none;
          border-radius: 0;
        }
        &:hover {
          color: $lightblue;
        }
      }
      span {
        padding-left: 0.8rem;
      }
    }
  }
  &__title {
    font-size: 1.2rem;
    font-weight: bold;
    background-image: none;
    color: $text-color;
  }
}

.podcast-box {
  &-1 {
    z-index: 2 !important;
    margin-bottom: 4rem;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      position: absolute;
      top: 6.4rem;
    }

    .card-box {
      background-color: $light1;
    }
  }
  &-2 {
    z-index: 2 !important;
    margin-bottom: 3.2rem;
    @include media-breakpoint-up(md) {
      margin-bottom: 6.4rem;
    }
    @include media-breakpoint-up(lg) {
      margin-top: 0;
      margin-bottom: 0;
      position: absolute;
      bottom: 6.4rem;
    }

    .card-box {
      background-color: $light3;
    }
  }
}

.row-spotify {
  margin-bottom: 6.4rem;
  @include media-breakpoint-down(lg) {
    justify-content: center;
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 3.2rem;
    margin-top: 6.4rem;
  }
}

.img-podcast {
  width: 100%;
  height: auto;
  display: block;
  margin-bottom: 3.2rem;
  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
  }
}

.podcast-col {
  margin-top: 4rem;
  border-radius: 20px;
  background-color: #901050;
  margin-bottom: 6.4rem;
  @include media-breakpoint-down(lg) {
    margin-left: 24px;
    margin-right: 24px;
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.podcast-title {
  padding-left: 1.5rem;
  color: white;
  font-size: 1.2rem;
  margin-top: 2rem;
  font-weight: 700;
  @include media-breakpoint-up(lg) {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
}
