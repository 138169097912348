html {
  font-size: 62.5%;
  min-height: 100vh;
}

body {
  font-size: 1.8rem;
  font-family: $main-font-family;
  font-weight: 500;
  color: $text-color;
  line-height: 1.44;
  font-feature-settings: "kern" 1;
  text-rendering: optimizeLegibility;
  font-kerning: normal;
  font-smooth: antialiased !important;
  font-smooth: always !important;
  min-height: 100vh;
  padding-top: 73px;
}

main {
  margin-top: 3.2rem;
  @include media-breakpoint-up(md) {
    margin-top: 9.6rem;
  }
}

.page-home {
  main {
    margin-top: 3.2rem;
    @include media-breakpoint-up(md) {
      margin-top: 3.6rem;
    }
  }
}

.page-error {
  main {
    margin-top: 0;
    padding-top: 3.2rem;
    @include media-breakpoint-up(md) {
      margin-top: 0;
      padding-top: 9.6rem;
    }
  }
}

dl,
ol,
ul {
  margin-bottom: 0;
}

a {
  color: $link-color;
  text-decoration: none;
  text-decoration: none;
  background-image: linear-gradient(180deg, transparent transparent);
  text-decoration: none;
  background-image: linear-gradient(white, white),
    linear-gradient($link-color, $link-color);
  background-size: 100% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.2s linear;
  &:hover {
    text-decoration: none;
    cursor: pointer;
    color: $link-color;
    background-size: 0 1px, 100% 1px;
  }
}

.container {
  max-width: 1200px;
  @media screen and (min-width: 360px) and (max-width: 575px) {
    padding-left: 24px;
    padding-right: 24px;
  }
  @media screen and (min-width: 575px) {
    padding-left: 12px;
    padding-right: 12px;
  }
  @media screen and(min-width: 1500px) {
    max-width: 1366px;
  }
}

.row {
  @media screen and (min-width: 360px) and (max-width: 575px) {
    margin-right: -24px;
    margin-left: -24px;
  }
  @media screen and (min-width: 575px) {
    margin-left: -12px;
    margin-right: -12px;
  }
}

[class^="col-"] {
  @media screen and (min-width: 360px) and (max-width: 575px) {
    padding-left: 24px;
    padding-right: 24px;
  }
  @media screen and (min-width: 575px) {
    padding-left: 12px;
    padding-right: 12px;
  }
  &.position-absolute {
    @include media-breakpoint-up(sm) {
      z-index: -1;
    }
  }
}

.nav-list,
.mobile-menu__list {
  li {
    a {
      color: $text-color;
      font-weight: 700;
      text-decoration: none;
      font-size: 1.2rem;
      line-height: 1;
      height: 100%;
      white-space: nowrap;
      position: relative;
      display: flex;
      align-items: center;
    }
  }
}

.nav-list {
  position: relative;
  overflow: hidden;
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  li {
    float: left;
    height: 100%;
    &#crazy-line-header,
    &#crazy-line-footer {
      position: absolute;
      bottom: 3px;
      left: 0;
      padding: 0;
      width: 100px;
      height: 2px;
      background: $lightblue;
    }
    a {
      margin-right: 1.7vw;
    }
    &:last-of-type a {
      padding-right: 0;
    }
  }
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.btn {
  color: white;
  line-height: 1;
  font-weight: 700;
  font-size: 1.2rem;
  border-radius: 40px;
  position: relative;
  transition: all 0.5s ease;
  background-image: none;
  &.focused-cta {
    animation: bounce 0.3s ease-out 1;
  }
  &--small {
    padding: 1.7rem 4rem;
  }
  &--big {
    padding: 2.1rem 4rem;
  }
  &--long {
    padding: 1.7rem 4.5rem;
  }
  &--light {
    background-color: $yellow;
    &:hover {
      background-color: darken($yellow, 10%);
    }
  }
  &--dark {
    background-color: $blue;
    &:hover {
      background-color: darken($blue, 10%);
    }
  }
  &--color3 {
    background-color: $lightblue;
    &:hover {
      background-color: darken($lightblue, 10%);
    }
  }
  &--foto {
    padding: 2.1rem 4rem 2.1rem 5.4rem;
  }
  &--submit {
    padding: 2.1rem 4.5rem;
  }
  span {
    color: white;
  }
  &:hover,
  &:focus,
  &:active {
    color: white;
    text-decoration: none;
    box-shadow: none;
  }
  &:hover {
    box-shadow: $button-shadow;
    text-decoration: none;
    background-image: none;
  }
  &--transparent {
    color: black;
    border-color: #9caed8;
    border-width: 2px;
    &:hover,
    &:focus,
    &:active {
      color: black;
    }
  }
}

.btn-img {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -30px;
}

.btn--light.focused-cta {
  background-color: $yellow;
}

.btn--dark.focused-cta {
  background-color: $blue;
}

.hero-intro {
  font-weight: 700;
  font-size: 2.4rem;
  margin-bottom: 1.6rem;
}

.section {
  position: relative;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  &__intro {
    font-weight: 600;
  }
  &__title {
    display: block;
    line-height: 1;
    margin-bottom: 3rem;
    @include media-breakpoint-up(lg) {
      text-align: left;
    }
    &--big {
      font-size: 3.2rem;
      @include media-breakpoint-up(md) {
        font-size: 4.8rem;
      }
      @include media-breakpoint-up(lg) {
        text-align: left;
      }
    }
    br {
      @media screen and (max-width: 374px) {
        display: none;
      }
    }
    &--small {
      font-size: 3.2rem;
      font-weight: bold;
      line-height: 1.25;
      @include media-breakpoint-up(md) {
        font-size: 3.6rem;
      }
    }
  }
  &__subtitle {
    display: block;
    line-height: 1.22;
    margin-bottom: 3rem;
    font-weight: bold;
    font-size: 2.4rem;
    @include media-breakpoint-up(lg) {
      font-size: 3.6rem;
    }
    &--small {
      font-size: 2.4rem;
      margin-bottom: 3.2rem;
      font-weight: bold;
    }
  }
  &__text {
    font-size: 1.8rem;
    line-height: 1.44;
    font-weight: 500;
    @include media-breakpoint-up(lg) {
      width: 100%;
      margin-left: 0;
    }
    @include media-breakpoint-up(xl) {
      width: 100%;
      margin-left: 0;
    }
    p {
      margin-bottom: 0;
      &:not(:last-of-type) {
        margin-bottom: 3.2rem;
      }
    }
    .span-light {
      font-weight: 400;
    }
    ul {
      list-style-type: none;
      padding-left: 0;
      position: relative;
      li {
        position: relative;
        &:before {
          content: "";
          background-position: center;
          background-repeat: no-repeat;
        }
      }
      &.list-simple {
        li {
          padding-left: 38px;
          display: block;
          &:not(:last-of-type) {
            margin-bottom: 1.5rem;
            @include media-breakpoint-up(lg) {
              margin-bottom: 1.6rem;
            }
          }
          &:before {
            display: block;
            width: 23px;
            height: 23px;
            top: 3px;
            border-radius: 100%;
            background-color: $lightblue;
            background-size: 10px;
            background-image: url("../assets/images/icons/icon_checked.svg");
            position: absolute;
            left: 0;
          }
        }
      }
    }
  }
}

.orbit-wrapper {
  position: absolute;
  z-index: 12;
}

.orbit {
  position: relative;
  &--long {
    animation-duration: 2000ms;
  }
  &--quick {
    animation-duration: 1500ms;
  }
  &--brian-big {
    animation-duration: 4000ms;
  }
  &--brian-small {
    animation-duration: 2000ms;
  }
  &--long-f {
    animation-duration: 2000ms;
  }
  &--quick-f {
    animation-duration: 1500ms;
  }
  &--brian-big-f {
    animation-duration: 2000ms;
  }
  &--brian-small-f {
    animation-duration: 1500ms;
  }
}

@keyframes spin-small {
  100% {
    transform: rotate(calc(1turn / 4));
  }
}

@keyframes spin-full {
  100% {
    transform: rotate(1turn);
  }
}

@keyframes spin-small-wrapper {
  100% {
    transform: none;
  }
}

@keyframes spin-full-wrapper {
  100% {
    transform: none;
  }
}

@keyframes spin-small-reverse {
  100% {
    transform: rotate(calc(-1turn / 4));
  }
}

@keyframes spin-full-reverse {
  100% {
    transform: rotate(-1turn);
  }
}

.square-positioner {
  position: absolute;
  z-index: -2;
}

.square {
  border-radius: 10px;
  @include media-breakpoint-up(md) {
    border-radius: 20px;
  }
  &--big {
    width: 204px;
    height: 204px;
  }
  &--average {
    width: 122px;
    height: 122px;
  }
  &--average-small {
    width: 100px;
    height: 100px;
  }
  &--small {
    width: 90px;
    height: 90px;
  }
  &--color1 {
    background-color: $light1;
  }
  &--color2 {
    background-color: $light2;
  }
  &--color3 {
    background-color: $light3;
  }
  &--color4 {
    background-color: $light1;
  }
  &--color5 {
    background-color: #ebd8d8;
  }
}

.scroll-top {
  background: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 1px;
  transition: 0.3s;
  .icon-wrapper {
    border: 1px solid transparent;
    width: 32px;
    height: 32px;
    background-color: $light3;
    transition: 0.2s;
    img {
      height: 12px;
      object-fit: contain;
    }
  }
  &:hover {
    .text-lighter {
      color: $link-color;
    }
    .icon-wrapper {
      background-color: darken(#dceaeb, 5%);
    }
  }
  &:focus {
    outline: none !important;
  }
}

/* Google ReCAPTCHA badge visibility hidden */

.grecaptcha-badge {
  visibility: hidden;
}

// HEADERS
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

.hero-title {
  font-size: 3.2rem;
  line-height: 1.25;
  line-height: 1.1;
  font-weight: bold;
  margin-bottom: 3.2rem;
  text-align: center;
  @include media-breakpoint-up(sm) {
    font-size: 4rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 5.6rem;
    text-align: left;
    line-height: 1;
  }
}

.card-box {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 32px 24px 32px 24px;
  padding: 24px;
  box-shadow: $box-shadow;
  border-radius: 20px;
  position: relative;
  background-color: white;
  @include media-breakpoint-up(md) {
    padding: 48px 3rem 48px 48px;
    padding: 4.8rem;
    align-items: center;
  }
  @media screen and (min-width: 1500px) {
    padding: 48px 6rem 48px 48px;
  }
  &--long {
    max-width: 43rem;
  }
  &--short {
    max-width: 31.7rem;
  }
  &--white {
    background-color: white;
  }
  &--transparent {
    background-color: transparent;
    box-shadow: none;
  }
  &--color1 {
    background-color: $light1;
  }
  &--color2 {
    background-color: $light2;
  }
  &--color3 {
    background-color: $light3;
  }
  &__border {
    width: calc(100% - (48px * 2));
    position: absolute;
    height: 2px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0 0 10px 10px;
    &--bcg1 {
      background-color: $border-color1;
    }
    &--bcg2 {
      background-color: $lightblue;
    }
    &--bcg3 {
      background-color: $yellow;
    }
  }
  &--noshadow {
    box-shadow: none;
  }
  &--disabled {
    box-shadow: none;
    .btn {
      pointer-events: none;
      opacity: 0.3;
    }
  }
  &__icon-wrap {
    margin-right: 2.4rem;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    &--color1 {
      background-color: $light1;
    }
    &--color2 {
      background-color: $light2;
    }
    &--color3 {
      background-color: $yellow;
    }
    &--color4 {
      background-color: $blue;
    }
    &--color5 {
      background-color: $light3;
    }
    &--color6 {
      background-color: $lightblue;
    }
  }
  &__desc {
    width: calc(100% - (48px + 2.4rem));
  }
  &__title {
    color: $text-color2;
    font-weight: bold;
    font-size: 1rem;
    display: inline-block;
    margin-bottom: 1rem;
    &--big {
      font-size: 2.4rem;
      line-height: 1.33;
      font-weight: bold;
      margin-bottom: 1.6rem;
      display: block;
    }
  }
  &__text {
    font-size: 1.8rem;
    line-height: 1.44;
    font-weight: 500;
  }
  .btn {
    margin-top: 0.8rem;
    margin-top: 1.6rem;
    @include media-breakpoint-up(lg) {
      margin-top: 3.2rem;
    }
  }
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  &--light {
    background-color: $light2;
  }
}

.title-mask {
  display: inline-block;
  position: relative;
  overflow: hidden;
  line-height: 1 !important;
}

.title-line {
  display: inline-block;
  opacity: 0;
  transform: none;
  will-change: opacity, transform;
  transition: 0.1s;
  line-height: 1.14 !important;
  &--under {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      height: 3px;
      background-color: $yellow;
      bottom: 0;
      display: block;
      left: 0;
      right: 0;
    }
  }
}

.title-mask {
  &.d1 .title-line {
    animation-delay: 100ms;
  }
  &.d2 .title-line {
    animation-delay: 200ms;
  }
  &.d3 .title-line {
    animation-delay: 300ms;
  }
  &.d4 .title-line {
    animation-delay: 400ms;
  }
  &.d5 .title-line {
    animation-delay: 500ms;
  }
  &.d6 .title-line {
    animation-delay: 600ms;
  }
  &.d7 .title-line {
    animation-delay: 700ms;
  }
  &.d8 .title-line {
    animation-delay: 800ms;
  }
  &.d9 .title-line {
    animation-delay: 900ms;
  }
  &.d10 .title-line {
    animation-delay: 1000ms;
  }
  &.d11 .title-line {
    animation-delay: 1100ms;
  }
  &.d12 .title-line {
    animation-delay: 1200ms;
  }
  &.d13 .title-line {
    animation-delay: 1300ms;
  }
  &.d14 .title-line {
    animation-delay: 1400ms;
  }
  &.d15 .title-line {
    animation-delay: 1500ms;
  }
  &.d16 .title-line {
    animation-delay: 1600ms;
  }
  &.d17 .title-line {
    animation-delay: 1700ms;
  }
  &.d18 .title-line {
    animation-delay: 1800ms;
  }
  &.d19 .title-line {
    animation-delay: 1900ms;
  }
  &.d20 .title-line {
    animation-delay: 2000ms;
  }
  &.d21 .title-line {
    animation-delay: 2100ms;
  }
  &.d22 .title-line {
    animation-delay: 2200ms;
  }
  &.d23 .title-line {
    animation-delay: 2300ms;
  }
  &.d24 .title-line {
    animation-delay: 2400ms;
  }
}

@keyframes revealText {
  from {
    opacity: 0;
    transform: translate(0%, 100%) matrix(1, 0, 0, 1, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate(0%, 0%) matrix(1, 0, 0, 1, 0, 0);
  }
}

.ill__wrapper--img-b img {
  @include media-breakpoint-down(sm) {
    max-width: 111% !important;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
  }
}

.underline-wrapper {
  display: block;
  margin-top: 3.2rem;
  margin-bottom: 3.2rem;
}

.span-underline {
  text-decoration: none;
  background-size: 100% 2px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(180deg, $yellow, $yellow);
}

.opinions {
  &--mobile {
    padding-left: 24px;
    padding-right: 24px;
    position: relative;
    .slick-track {
      padding-top: 16px;
    }
  }
}

.opinion {
  &__box {
    height: auto;
    border: 1px solid $light3;
    border-radius: 20px;
    margin-bottom: 40px;
    padding: 96px 3rem 2.4rem 3rem;
    @media screen and (min-width: 375px) {
      padding: 96px 5.7rem 2.4rem 5.7rem;
    }
    @include media-breakpoint-up(md) {
      padding: 96px 4.8rem 5.8rem 4.8rem;
    }
    position: relative;
    &--brian {
      background-color: $light1;
      padding-top: 0;
      border-radius: 20px;
      border-color: transparent;
      position: relative;
      margin-top: 9rem;
      padding: 3.2rem 24px;
      @include media-breakpoint-up(md) {
        padding: 3.2rem 24px 58px 3.2rem;
        margin-top: 0;
        padding: 0 48px 58px 48px;
      }
      p {
        font-weight: 600;
      }
      &:before {
        content: "";
        top: 0;
        width: calc(100% + 2px);
        height: 3.2rem;
        background-color: $light1;
        position: absolute;
        border: 1px solid transparent;
        border-radius: 20px 20px 0 0;
        top: -3rem;
        display: block;
        left: -1px;
        z-index: -1;
        display: none;
        @include media-breakpoint-up(md) {
          display: block;
          height: 6.4rem;
          top: -5rem;
        }
      }
      .triangle-tl:before {
        top: -73px;
      }
    }
  }
  &__avatar {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    position: absolute;
    top: -16px;
    left: 50%;
    transform: translate(-50%);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  &__text {
    font-size: 1.8rem;
    font-weight: 500;
    text-align: center;
    font-style: italic;
  }
  &__name {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 1.6rem;
    font-weight: 600;
    font-weight: 700;
    font-style: normal;
  }
}

.slider-navigate {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 6.4rem;
  padding-bottom: 2rem;
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
}

.slide-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  color: transparent;
  margin: 0 8px;
  border: none;
  border-radius: 100%;
  transition: $transition-time;
  background-color: $light3;
  padding: 0;
  &:hover,
  &:focus {
    box-shadow: $arrow-shadow;
    outline: none;
  }
  img {
    transition: $transition-time;
  }
}

.mt {
  margin-top: 3.2rem;
  @include media-breakpoint-up(lg) {
    margin-top: 6.4rem;
  }
}

.mb {
  margin-bottom: 3.2rem;
  @include media-breakpoint-up(lg) {
    margin-bottom: 6.4rem;
  }
}

.ml {
  margin-left: 3.2rem;
  @include media-breakpoint-up(lg) {
    margin-left: 6.4rem;
  }
}

.mr {
  margin-right: 3.2rem;
  @include media-breakpoint-up(lg) {
    margin-right: 6.4rem;
  }
}

.container-beyond {
  position: absolute;
  overflow: hidden;
  left: 50%;
  transform: translateX(-50%);
  font-display: 1px;
  min-height: 500px;
  @include media-breakpoint-up(lg) {
    overflow: visible;
  }
}

.text-lighter {
  color: $text-color2;
}

@media (min-width: 1200px) and (-webkit-device-pixel-ratio: 1.25) and (max-width: 1920px) {
  html {
    font-size: 50%;
  }
  .container {
    max-width: 1140px !important;
  }
}

