.home-hero {
  &__text {
    text-align: center;
    @include media-breakpoint-up(lg) {
      text-align: left;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      padding-top: 3rem;
    }
    @media screen and (min-width: 1399px) {
      padding-top: 6.4rem;
    }
    br {
      @include media-breakpoint-down(xs) {
        display: none;
      }
      &.mob {
        display: none;
        @media screen and (max-width: 400px) {
          display: inline-block;
        }
      }
    }
  }
  &__intro {
    font-size: 2.4rem;
    font-weight: bold;
    margin-bottom: 3.2rem;
  }
  &__foto {
    border-radius: 20px;
    @include media-breakpoint-down(md) {
      margin-bottom: 3.2rem;
    }
    img {
      width: 100%;
      height: 100%;
      max-width: 77.4rem;
      max-height: 68.2rem;
      object-fit: cover;
      @media screen and (max-width: 400px) {
        max-height: 277px;
      }
      @media screen and (min-width: 1920px) {
        height: 68.2rem;
      }
    }
  }
  &__desc {
    margin-bottom: 3.2rem;
  }
  .btn--foto {
    margin-left: 30px;
    margin-top: 0.2rem;
    @include media-breakpoint-up(lg) {
      margin-top: 3.4rem;
    }
  }
}

.home-logo {
  margin-top: 8rem;
  margin-bottom: 7.8rem;
  @include media-breakpoint-up(lg) {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }
}

.dev-logos {
  height: 208px;
  width: 100%;
  position: relative;
  @include media-breakpoint-down(md) {
    max-width: 375px !important;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3.2rem;
  }
  @include media-breakpoint-up(lg) {
    height: 452px;
  }
  .logo-wrapper {
    position: absolute;
    .super-logo-wrapper {
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: $box-shadow;
      border-radius: 50%;
      &--small {
        height: 77px;
        width: 77px;
        @include media-breakpoint-up(lg) {
          width: 157px;
          height: 157px;
        }
      }
      &--big {
        width: 135px;
        height: 135px;
        @include media-breakpoint-up(lg) {
          width: 230px;
          height: 230px;
        }
        @media screen and (min-width: 1500px) {
          width: 270px;
          height: 270px;
        }
      }
    }
  }
  .react {
    top: -6px;
    left: 36px;
    @include media-breakpoint-up(lg) {
      top: 46px;
      left: 40px;
    }
    img {
      @include media-breakpoint-down(lg) {
        height: 28px;
        width: 31px;
      }
      @include media-breakpoint-up(lg) {
        height: 56px;
        width: 63px;
      }
    }
  }
  .js, .ai {
    left: 55%;
    top: 20%;
    @include media-breakpoint-up(lg) {
      top: 30%;
      left: 55%;
    }
    .js-img, .ai-img {
      @include media-breakpoint-down(lg) {
        height: 44px;
        width: 44px;
      }
      @include media-breakpoint-up(lg) {
        height: 88px;
        width: 88px;
        top: 46px;
        left: 24px;
      }
    }
  }
  .ruby {
    left: 20%;
    top: 10%;
    @include media-breakpoint-up(lg) {
      top: 77px;
    }
    @media screen and (min-width: 1500px) {
      top: 60px;
    }
    .ruby-img {
      height: 318px;
      width: 295px;
      object-fit: contain;
      @include media-breakpoint-down(lg) {
        width: 64px;
        height: 64px;
      }
      @include media-breakpoint-up(lg) {
        height: 128px;
        width: 128px;
      }
    }
  }
  .angular {
    top: -32px;
    left: 61%;
    @include media-breakpoint-up(lg) {
      top: 0;
      left: 59%;
    }
    img {
      @include media-breakpoint-down(lg) {
        height: 28px;
        width: 26px;
      }
      @include media-breakpoint-up(lg) {
        height: 56px;
        width: 53px;
      }
    }
  }
  .docker {
    top: -10px;
    right: 20px;
    @include media-breakpoint-up(lg) {
      top: 70px;
      right: 32px;
    }
    img {
      @include media-breakpoint-down(lg) {
        height: 16px;
        width: 28px;
      }
      @include media-breakpoint-up(lg) {
        height: 32px;
        width: 56px;
      }
    }
  }
  .kubernetes {
    top: 26%;
    right: 15px;
    @include media-breakpoint-up(lg) {
      top: 42%;
      right: 0;
    }
    img {
      @include media-breakpoint-down(lg) {
        height: 16px;
        width: 16px;
      }
      @include media-breakpoint-up(lg) {
        height: 32px;
        width: 33px;
      }
    }
  }
  .node-js {
    right: 30px;
    bottom: 40px;
    @include media-breakpoint-up(lg) {
      right: 41px;
      bottom: 88px;
    }
    img {
      @include media-breakpoint-down(lg) {
        height: 28px;
        width: 24px;
      }
      @include media-breakpoint-up(lg) {
        height: 56px;
        width: 50px;
      }
    }
  }
  .elixir {
    bottom: 15px;
    left: 58%;
    @include media-breakpoint-up(lg) {
      bottom: 32px;
      left: 55%;
    }
    img {
      @include media-breakpoint-down(lg) {
        height: 28px;
        width: 19px;
      }
      @include media-breakpoint-up(lg) {
        height: 56px;
        width: 38px;
      }
    }
  }
  .aws {
    bottom: 0;
    left: 22%;
    img {
      @include media-breakpoint-down(lg) {
        width: 30px;
      }
      @include media-breakpoint-up(lg) {
        height: 32px;
        width: 41px;
      }
    }
  }
  .vue {
    left: 24px;
    bottom: 30%;
    @include media-breakpoint-up(lg) {
      left: 32px;
    }
    img {
      @include media-breakpoint-down(lg) {
        height: 28px;
        width: 32px;
      }
      @include media-breakpoint-up(lg) {
        height: 56px;
        width: 65px;
      }
    }
  }
}

.page-home--landing {
  .dev-logos {
    @include media-breakpoint-down(md) {
      max-width: 300px !important;
    }
    .js, .ai {
      left: 50%;
      transform: translateX(-50%);
      @include media-breakpoint-up(lg) {
        top: 50%;
        left: 40%;
        transform: translate(-50%, -50%);
      }
    }
    .tensorflow {
      top: -32px;
      left: 41%;
      @include media-breakpoint-up(lg) {
        top: 0;
        left: 39%;
      }
      img {
        @include media-breakpoint-down(lg) {
          height: 28px;
          width: 146px;
        }
        @include media-breakpoint-up(lg) {
          height: 35px;
          width: 183px;
        }
      }
    }
    .llama {
      top: 30px;
      right: -15px;
      @include media-breakpoint-up(lg) {
        top: 90px;
        right: 12px;
      }
      img {
        @include media-breakpoint-down(lg) {
          height: 20px;
          width: 87px;
        }
        @include media-breakpoint-up(lg) {
          height: 25px;
          width: 109px;
        }
      }
    }
    .pytorch {
      top: 46%;
      right: -35px;
      @include media-breakpoint-up(lg) {
        top: 52%;
        right: 0;
      }
      img {
        @include media-breakpoint-down(lg) {
          height: 25px;
          width: 94px;
        }
        @include media-breakpoint-up(lg) {
          height: 30px;
          width: 113px;
        }
      }
    }
    .sagemaker {
      bottom: 15px;
      left: 58%;
      @include media-breakpoint-up(lg) {
        bottom: 32px;
        left: 55%;
      }
      img {
        @include media-breakpoint-down(lg) {
          height: 28px;
          width: 89px;
        }
        @include media-breakpoint-up(lg) {
          height: 35px;
          width: 111px;
        }
      }
    }
    .chroma {
      bottom: 25px;
      left: 5%;
      @include media-breakpoint-up(lg) {
        bottom: 52px;
        left: 3%;
      }
      img {
        @include media-breakpoint-down(lg) {
          height: 60px;
          width: 60px;
        }
        @include media-breakpoint-up(lg) {
          height: 80px;
          width: 80px;
        }
      }
    }
    .langchain {
      left: -44px;
      top: 40%;
      @include media-breakpoint-up(lg) {
        left: -62px;
      }
      img {
        @include media-breakpoint-down(lg) {
          height: 30px;
          width: 43px;
        }
        @include media-breakpoint-up(lg) {
          height: 60px;
          width: 85px;
        }
      }
    }
    .pinecone {
      top: -6px;
      left: -36px;
      @include media-breakpoint-up(lg) {
        top: 46px;
        left: -40px;
      }
      img {
        @include media-breakpoint-down(lg) {
          height: 30px;
          width: 110px;
        }
        @include media-breakpoint-up(lg) {
          height: 40px;
          width: 146px;
        }
      }
    }
    .vue {
      left: 7px;
      bottom: 25%;
      @include media-breakpoint-up(lg) {
        left: -32px;
      }
      img {
        height: 28px;
        width: 32px;
        @include media-breakpoint-up(md) {
          height: 56px;
          width: 65px;
        }
      }
    }
    .node-js {
      right: 25px;
      bottom: 34px;
      @include media-breakpoint-up(lg) {
        right: 95px;
        bottom: 55px;
      }
      img {
        @include media-breakpoint-down(md) {
          height: 33px;
          width: 28px;
        }
      }
    }
    .angular {
      top: -14px;
      left: 70%;
      @include media-breakpoint-up(lg) {
        top: 57px;
        right: 95px;
        left: unset;
      }
      img {
        @include media-breakpoint-down(md) {
          height: 35px;
          width: 32px;
        }
      }
    }
    .react {
      top: 0;
      left: 13px;
      @include media-breakpoint-up(lg) {
        top: 80px;
      }
      img {
        @include media-breakpoint-down(md) {
          height: 28px;
          width: 31px;
        }
      }
    }
  }
}

.home-roots {
  margin-bottom: 6.4rem;
  @include media-breakpoint-up(lg) {
    margin-bottom: 12.8rem;
  }
  .card-box {
    margin-top: 3.2rem;
    @include media-breakpoint-down(lg) {
      margin-left: auto;
      margin-right: auto;
    }
    @include media-breakpoint-up(lg) {
      margin-top: 6.4rem;
    }
    @include media-breakpoint-up(xl) {
      margin-top: 3.2rem;
    }
  }
}

.home-horizon {
  margin-bottom: 6.4rem;
  @include media-breakpoint-up(lg) {
    margin-bottom: 20rem;
  }
  &__intro {
    margin-bottom: 3.2rem;
    @include media-breakpoint-up(lg) {
      margin-bottom: 6.4rem;
    }
  }
  .card-box {
    z-index: 10;
    top: 102px;
    background-color: #fff;
    position: static !important;
    @include media-breakpoint-up(sm) {
      margin-left: auto;
      margin-right: auto;
    }
    @include media-breakpoint-down(md) {
      max-width: 43rem;
      padding: 32px;
    }
    @include media-breakpoint-up(lg) {
      position: absolute !important;
      margin-left: unset;
      margin-right: unset;
      padding: 48px;
    }
  }
}

.home-success {
  margin-bottom: 6.4rem;
  @include media-breakpoint-up(lg) {
    margin-bottom: 15.8rem;
  }
}

.home-difference {
  margin-bottom: 5rem;
  .orbit-wrapper {
    z-index: -1;
  }
  .boxes-row {
    @include media-breakpoint-up(lg) {
      margin-top: 6.4rem;
    }
  }
  .card-box {
    padding: 24px;
    margin-bottom: 24px;
    @media screen and (max-width: 575px) {
      display: flex;
      flex-direction: column;
      max-width: 326px;
      margin-left: auto;
      margin-right: auto;
    }
    @include media-breakpoint-up(lg) {
      min-height: 292px;
    }
    &__desc {
      @include media-breakpoint-down(sm) {
        padding-top: 20px;
      }
      @include media-breakpoint-up(lg) {
        width: calc(100% - 154px);
      }
    }
  }
  .bg--color4 {
    background-color: $light4;
  }
  .bg--color5 {
    background-color: $light5;
  }
  .card-box__top {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    @media screen and (min-width: 375px) {
      flex-wrap: nowrap;
      text-align: left;
      justify-content: start;
    }
    @media screen and (min-width: 1499px) {
      align-items: center;
    }
  }
  .card-box__title--big {
    @include media-breakpoint-down(sm) {
      font-size: 1.8rem;
    }
    @include media-breakpoint-up(lg) {
      font-size: 2rem;
    }
    @include media-breakpoint-up(lg) {
      font-size: 2.4rem;
    }
    br {
      display: none;
      @media screen and (min-width: 1499px) {
        display: inline-block;
      }
    }
  }
  .card__box-ill {
    @include media-breakpoint-down(md) {
    }
    .ill__wrapper {
      height: 113px;
      width: 102px;
      margin-top: 25px;
      position: relative;
      border-radius: 10px;
      margin-right: 16px;
      @include media-breakpoint-up(md) {
        margin-right: 40px;
      }
      @include media-breakpoint-up(lg) {
        width: 160px;
        height: 200px;
        margin-right: 30px;
        margin-top: 0;
      }
      @media screen and (min-width: 1499px) {
        width: 213px;
        height: 256px;
        margin-right: 43px;
      }
      .anim {
        position: absolute;
        bottom: 0;
        left: 50%;
        height: 100%;
        transform: translateX(-50%);
        width: 100%;
      }
      img {
        height: calc(100% + 10px);
        max-width: unset;
        max-height: unset;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
      &#difference_ill_1 img {
        bottom: 10px;
        @include media-breakpoint-up(lg) {
          bottom: 20px;
        }
      }
    }
  }
}

.link-box {
  display: flex;
  flex-wrap: wrap;
  &__desc {
    width: calc(100% - 204px);
  }
}

.avatar {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  object-fit: cover;
  &--big {
    width: 120px;
    height: 120px;
  }
}

.home-opinions {
  margin-bottom: 3.2rem;
  @include media-breakpoint-up(lg) {
    margin-bottom: 12.8rem;
  }
}

.brian-title {
  margin-top: 3.2rem;
  @include media-breakpoint-up(md) {
    margin-bottom: 6.4rem;
    margin-top: 8rem;
  }
}

.opinions-top {
  display: flex;
  justify-content: space-between;
  @include media-breakpoint-down(md) {
    max-width: 360px;
    margin-left: auto;
    margin-right: auto;
  }
  .avatars-wrap {
    height: 261px;
    width: calc(100% - 200px);
    position: relative;
    .logotype {
      position: absolute;
      object-fit: contain;
    }
    #logo {
      &1 {
        height: 32px;
        width: 124px;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
      &2 {
        width: 87px;
        top: 183px;
        left: 11px;
        @include media-breakpoint-up(lg) {
          top: -15px;
          left: 140px;
        }
      }
      &3 {
        width: 35px;
        bottom: 135px;
        left: 47px;
        @include media-breakpoint-up(lg) {
          width: 126px;
          bottom: 0;
          left: 152px;
        }
      }
      &3m {
        width: 39px;
        bottom: 135px;
        left: 29px;
      }
      &4 {
        width: 90px;
        top: 49px;
        right: 20px;
      }
      &5 {
        bottom: 62px;
        right: 50px;
        @include media-breakpoint-up(lg) {
          bottom: 73px;
          right: 57px;
        }
      }
      &6 {
        width: 76px;
        right: 260%;
        top: -1px;
        @include media-breakpoint-up(lg) {
          width: 106px;
          right: 100%;
          top: -48px;
        }
      }
      &7 {
        bottom: 49px;
        left: -28px;
        width: 50px;
        @include media-breakpoint-up(lg) {
          width: 60px;
          bottom: 0;
          left: 23px;
        }
      }
      &8 {
        width: 36px;
        right: 53px;
        bottom: 81%;
        @include media-breakpoint-up(lg) {
          width: 95px;
          right: 175px;
          bottom: 73%;
        }
      }
      &9 {
        height: 21px;
        right: 0;
        top: 92px;
      }
      &10 {
        width: 67px;
        right: -8px;
        bottom: 55%;
        @include media-breakpoint-up(lg) {
          width: 70px;
          right: 165px;
          bottom: 96px;
        }
      }
    }
  }
  .brian-header {
    min-width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
}

.append-btn-wrapper {
  margin-top: 2.4rem;
  text-align: center;
}

.row-line {
  position: relative;
  .line-drawn {
    position: absolute;
    left: 15px;
    bottom: 76px;
    height: 1px;
    z-index: -1;
    background-color: $light3;
    display: none;
    @media screen and (min-width: 1499px) {
      display: block;
    }
  }
}

.home-difference {
  .row-line {
    .line-drawn {
      bottom: 95px;
    }
  }
}

.horizon-box {
  .card-box__text {
    margin-top: 2.4rem;
  }
}

.ruby-orbit-mobile {
  width: 168px;
  height: 168px;
}
