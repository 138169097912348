.page-about {
	.hero-title {
		@include media-breakpoint-up(lg) {
			margin-bottom: 16.5rem;
		}
	}
	.section__title {
		margin-top: 2rem;
	}
	.part-2 {
		margin-top: 6.4rem;
		margin-bottom: 12rem;
		@include media-breakpoint-up(xl) {
			margin-top: 25rem;
			margin-bottom: 36rem;
		}
		.quote-img {
			left: calc(100% - 150px);
		}
	}
	.part-3 {
		@include media-breakpoint-down(lg) {
			margin-bottom: 9.5rem;
		}
	}
}

.title-boxed {
	display: inline-block;
	color: white;
	font-size: 1.2rem;
	padding: 0.5rem 1rem;
	font-weight: bold;
	background-color: $yellow;
	border-radius: 5px;
}

.about-hero {
	&__intro {
		margin-bottom: 9.7rem;
		@include media-breakpoint-up(lg) {
			margin-bottom: 18.6rem;
		}
	}
}

.row-quote {
	@include media-breakpoint-up(lg) {
		margin-bottom: 12.8rem;
	}
}

.quote {
	@include media-breakpoint-down(lg) {
		padding-left: 24px;
		padding-right: 24px;
	}
	&--1 {
		@include media-breakpoint-up(lg) {
			padding-left: 3.5rem;
		}
	}
	&--2 {
		@include media-breakpoint-up(lg) {
			padding-left: 7.6rem;
			padding-right: 8.7rem;
		}
	}
}

.quote-wrapper {
	position: absolute;
	height: calc(100% + 64px);
	border-radius: 20px;
	left: 24px;
	top: -32px;
	z-index: -1;
	border: 1px solid $light3;
	width: calc(100% - 48px);
	@include media-breakpoint-up(lg) {
		height: calc(100% + 128px);
		left: 12px;
		width: calc(100% - 24px);
		top: -65px;
	}
	.quote-img {
		position: relative;
		top: -45px;
		left: 64px;
		@include media-breakpoint-down(lg) {
			width: 64px;
			height: 64px;
			top: -32px;
		}
		&--mr {
			left: calc(100% - 88px) !important;
		}
	}
}

.triangle {
	&:after,
	&:before {
		content: "";
		position: absolute;
		width: 0;
		height: 0;
		border: 0 solid transparent;
	}
	&-tl,
	&-tr,
	&-br,
	&-bl,
	&-fl,
	&-fr {
		&:after,
		&:before {
			content: "";
			position: absolute;
			width: 0;
			height: 0;
			border: 0 solid transparent;
		}
		&:after {
			z-index: -1;
		}
		&:before {
			z-index: 2;
		}
	}
	&-tl {
		&:after {
			top: -27px;
			left: 6.3rem;
			border-top-width: 27px;
			border-bottom-width: 0;
			border-right: 35px solid $light3;
		}
		&:before {
			top: -25px;
			left: 6.5rem;
			border-top-width: 25px;
			border-bottom-width: 0;
			border-right: 32px solid #fff;
		}
		&--orange {
			&:after {
				border-right: 35px solid $light1;
			}
			&:before {
				border-right: 32px solid $light1;
			}
		}
		&--green {
			&:after {
				border-right: 35px solid $light3
			}
			&:before {
				border-right: 32px solid $light3
			}
		}
	}
	&-tr {
		&:after {
			top: -24px;
			right: 65px;
			border-bottom-width: 0px;
			border-top-width: 24px;
			border-left: 32px solid $light3;
		}
		&:before {
			top: -22px;
			right: 66px;
			border-bottom-width: 0px;
			border-top-width: 23px;
			border-left: 30px solid #ffffff;
		}
		&--blue {
			&:after {
				border-left: 35px solid $light2;
			}
			&:before {
				border-left: 32px solid $light2;
			}
		}
	}
	&-br {
		&:after {
			bottom: 65px;
			right: -24px;
			border-left-width: 0px;
			border-right-width: 24px;
			border-top: 32px solid $light3;
		}
		&:before {
			bottom: 66px;
			right: -22px;
			border-left-width: 0px;
			border-right-width: 23px;
			border-top: 30px solid #ffffff;
		}
	}
	&-bl {
		&:after {
			bottom: 64px;
			left: -24px;
			border-top-width: 0px;
			border-bottom-width: 32px;
			border-right: 24px solid $light3;
		}
		&:before {
			bottom: 65px;
			left: -22px;
			border-top-width: 0px;
			border-bottom-width: 30px;
			border-right: 23px solid #ffffff;
		}
	}
	&-fl {
		&:after {
			bottom: -24px;
			left: 64px;
			border-left-width: 32px;
			border-right-width: 0px;
			border-top: 24px solid $light3;
		}
		&:before {
			bottom: -22px;
			left: 64px;
			border-left-width: 30px;
			border-right-width: 0px;
			border-top: 23px solid #ffffff;
		}
	}
	&-fr {
		&:after {
			bottom: -24px;
			right: 64px;
			border-left-width: 0px;
			border-right-width: 32px;
			border-top: 24px solid $light3;
		}
		&:before {
			bottom: -22px;
			right: 65px;
			border-left-width: 0px;
			border-right-width: 30px;
			border-top: 23px solid #ffffff;
		}
	}
}

.steps-half {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	position: relative;
	@include media-breakpoint-up(xl) {
		padding-top: 10.4rem;
	}
	.section__subtitle {
		margin-top: 3.2rem;
		@include media-breakpoint-up(md) {
			font-size: 3.2rem;
			margin-top: 10.6rem;
			margin-top: 0;
		}
		@include media-breakpoint-up(xl) {
			margin-top: 0;
		}
	}
	.line-nos {
		display: none;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		height: 100%;
		flex-direction: column;
		@include media-breakpoint-up(lg) {
			display: block;
		}
		.line-number {
			&:first-of-type {
				margin-top: 10.4rem;
			}
			&:nth-of-type(2) {
				margin-top: 12.8rem;
			}
			&:nth-of-type(3) {
				bottom: 11rem;
				position: absolute;
			}
		}
		.dot {
			width: 8px;
			height: 8px;
			background-color: $blue;
			border-radius: 50%;
			left: 50%;
			position: absolute;
			transform: translateX(-50%);
			top: -5px;
		}
	}
}

.line-number {
	color: $text-color;
	font-size: 1.2rem;
	font-weight: bold;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	background-color: $light2;
	border-radius: 50%;
	position: relative;
	&--m {
		margin-left: auto;
		margin-right: auto;
		@include media-breakpoint-up(lg) {
			display: none;
		}
		.line-small {
			position: absolute;
			height: 32px;
			left: 50%;
			top: -32px;
			transform: translateX(-50%);
			width: 2px;
		}
		.dot {
			width: 8px;
			height: 8px;
			border-radius: 50%;
			left: 50%;
			position: absolute;
			transform: translateX(-50%);
			top: -40px;
		}
	}
}

.line {
	width: 1px;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	z-index: -1;
	.outer {
		position: relative;
		width: 100%;
		min-height: 100%;
		display: block;
		overflow: hidden;
		background-color: $light2;
		.inner {
			top: 0;
			width: 100%;
			display: block;
			position: absolute;
			background-color: $blue;
			z-index: 10;
			transition: 0.5s;
		}
	}
	&--1 {
		height: 10.6rem;
		top: -10.6rem;
	}
	&--2 {
		height: 14rem;
		top: 4rem;
	}
	&--3 {
		height: 38rem;
		@media screen and (min-width: 1500px) {
			height: 30rem;
		}
	}
}

.about-step {
	width: 100%;
	@include media-breakpoint-up(lg) {
		width: calc(50% - 59px);
	}
	&--1 {
		@include media-breakpoint-up(lg) {
			padding-top: 10.3rem;
		}
		@media screen and (min-width: 1200px) {
			padding-top: 0;
		}
	}
	&--2 {
		padding-top: 6.4rem;
		margin-bottom: 6.4rem;
		@include media-breakpoint-up(lg) {
			margin-bottom: 9.7rem;
			padding-top: 27.6rem;
		}
		@media screen and (min-width: 1200px) {
			padding-top: 17rem;
		}
	}
	&--3 {
		.section__subtitle {
			margin-top: 3.2rem;
		}
	}
}

.blockquote {
	text-align: center;
	font-style: italic;
	font-size: 2.4rem;
	margin-bottom: 2.4rem;
	color: $text-color;
	font-weight: 500;
}

.quote-line {
	width: 100%;
	height: 1px;
	background-color: $light3;
	margin-top: 3.2rem;
	margin-bottom: 3.2rem;
}

.about-opinions {
	margin-top: 6.4rem;
	margin-bottom: 6.4rem;
	@include media-breakpoint-up(md) {
		margin-top: 12.8rem;
		margin-bottom: 12.8rem;
	}
}

.about-mission {
	@include media-breakpoint-up(lg) {
		margin-top: 19.2rem;
	}
	h2.section__title {
		margin-bottom: 3.2rem;
		@media screen and (min-width: 400px) {
			margin-bottom: 6.4rem;
		}
		@include media-breakpoint-up(lg) {
			margin-bottom: 12.8rem;
		}
	}
	.blockquote {
		max-width: 36.8rem;
		margin-left: auto;
		margin-right: auto;
	}
}

.mission-box {
	margin-top: 9.6rem;
	margin-bottom: 6.4rem;
	position: relative;
	@include media-breakpoint-up(lg) {
		margin-bottom: 20rem;
	}
	&__bcg {
		width: calc(100% - 48px);
		position: absolute;
		background-color: $light1;
		border-radius: 20px;
		top: 0;
		left: 24px;
		height: 475px;
		@include media-breakpoint-up(md) {
			top: -2.4rem;
			height: calc(100% + 4.8rem);
			left: 12px;
			height: calc(100% + 4.8rem);
		}
	}
	.card-box {
		@include media-breakpoint-up(md) {
			align-items: flex-start;
		}
		&--long {
			max-width: calc(100% - 48px);
			@media screen and (min-width: 576px) {
				max-width: 43.1rem;
				width: 100%;
			}
			@include media-breakpoint-up(lg) {
				max-width: 43.1rem;
				width: 100%;
			}
		}
	}
}

.card-box {
	&--col {
		align-items: flex-start;
		align-items: center;
		flex-direction: column;
		@include media-breakpoint-up(md) {
			flex-direction: row;
			align-items: start;
		}
		.card-box__icon-wrap {
			@include media-breakpoint-down(md) {
				margin-bottom: 2.4rem;
			}
			@include media-breakpoint-down(sm) {
				margin-right: 0;
			}
		}
		.card-box__desc {
			@include media-breakpoint-down(md) {
				width: 245px;
			}
		}
	}
}

.genuinely-section {
	margin-bottom: 5.8rem;
	@include media-breakpoint-up(lg) {
		margin-bottom: 12.8rem;
	}
	.section__title--small {
		@include media-breakpoint-down(sm) {
			max-width: 262px;
			margin-left: auto;
			margin-right: auto;
		}
	}
	.section__intro {
		margin-bottom: 3.2rem;
	}
}

.about-genuinely-top {
	margin-bottom: 10.6rem;
	position: relative;
	@include media-breakpoint-up(lg) {
		margin-bottom: 19.6rem;
	}
	&:after {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		content: url(../assets/images/separator_mobile.svg);
		z-index: 2;
		@include media-breakpoint-up(lg) {
			content: url(../assets/images/separator.svg);
		}
	}
	.blockquote {
		margin-top: 3.2rem;
		margin-bottom: 6.4rem;
	}
}

.text-box {
	position: relative;
	border-radius: 15px;
	padding: 1.6rem 2.4rem;
	font-weight: 600;
	display: inline-block;
	&--yes,
	&--no {
		&:after {
			position: absolute;
			z-index: 2;
			border-radius: 4px;
			color: white;
			font-size: 1.2rem;
			padding: 4px 8px;
		}
	}
	&--yes {
		background-color: $light3;
		color: $link-color;
		margin-bottom: 0.8rem !important;
		&:after {
			content: "YES!";
			top: 50%;
			right: -2.4rem;
			transform: translateY(-50%);
			background-color: $lightblue;
		}
	}
	&--no {
		background-color: #ebd8d8;
		color: $border-color1;
		&:after {
			content: "NO!";
			top: 50%;
			right: -2.4rem;
			transform: translateY(-50%);
			background-color: $border-color1;
		}
	}
	&--checked {
		background-color: $light3;
		color: $text-color;
		padding-right: 4.5rem;
		margin-bottom: 0.8rem;
		position: relative;
		z-index: 5;
		&:after {
			content: "";
			display: block;
			width: 23px;
			height: 23px;
			border-radius: 100%;
			background-color: $lightblue;
			background-size: 10px;
			background-image: url("../assets/images/icons/icon_checked.svg");
			background-position: center;
			background-repeat: no-repeat;
			position: absolute;
			right: 6px;
			top: 6px;
		}
	}
	br {
		display: none;
		@include media-breakpoint-up(xl) {
			display: inline;
		}
	}
}

.section-collaboration {
	margin-bottom: 12.1rem;
	@include media-breakpoint-up(lg) {
		margin-bottom: 19.2rem;
	}
	.collab-top {
		margin-bottom: 12.1rem;
		@include media-breakpoint-up(lg) {
			margin-bottom: 16.2rem;
		}
		.section__subtitle--small {
			margin-top: 2.8rem;
		}
		.text-box {
			@include media-breakpoint-down(md) {
				max-width: calc(100% - 24px);
			}
		}
	}
	.collab-box-wrapper {
		height: 100%;
		display: flex;
		align-items: flex-end;
		@include media-breakpoint-up(lg) {
			position: absolute;
			top: 90px;
		}
	}
	.collab-box {
		padding: 4.8rem;
		background-color: $light2;
		border-radius: 20px;
		z-index: 2;
		display: flex;
		margin-top: 5.2rem;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		align-items: center;
		@include media-breakpoint-up(lg) {
			margin-left: auto;
			margin-top: 0;
			max-width: 388px;
			flex-direction: row;
			align-items: start;
			justify-content: start;
			text-align: left;
		}
		.card-box__desc {
			@include media-breakpoint-down(md) {
				width: 100%;
				max-width: 100%;
			}
		}
		.card-box__icon-wrap {
			@include media-breakpoint-down(md) {
				margin-right: 0;
				margin-bottom: 2.4rem;
			}
		}
	}
}

.section-welcome {
	padding-bottom: 5rem;
	.section__title {
		margin-bottom: 7.2rem;
		@include media-breakpoint-up(lg) {
			margin-bottom: 10.2rem;
		}
	}
	.card-box__desc {
		@include media-breakpoint-down(lg) {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;
			width: 100%;
			max-width: 100%;
		}
	}
}

.pr-big {
	@include media-breakpoint-up(lg) {
		padding-right: 6.4rem;
	}
	br {
		display: none;
		@media screen and (min-width: 1560px) {
			display: inline;
		}
	}
}

.box-border {
	padding-bottom: 3.2rem;
	position: relative;
	@include media-breakpoint-up(md) {
		padding-bottom: 6.4rem;
	}
	&-1 {
		.line1 {
			@include media-breakpoint-up(md) {
				background-color: $lightblue;
				position: absolute;
				bottom: 0;
				left: 12px;
				height: 1px;
			}
		}
	}
	&-2 {
		.line2 {
			@include media-breakpoint-up(md) {
				background-color: $light3;
				position: absolute;
				bottom: 0;
				left: 12px;
				height: 1px;
			}
		}
	}
}

.steps-intro {
	margin-bottom: 6.4rem;
	@include media-breakpoint-up(lg) {
		margin-bottom: 3.2rem;
	}
}

.section-opinions {
	position: relative;
	&:before {
		@include media-breakpoint-down(md) {
			content: "";
			position: absolute;
			top: -6.4rem;
			left: 0;
			width: 100%;
			height: 1px;
			background-color: $lightblue;
		}
	}
}

.box-border-1 {
	.section__subtitle--small {
		@include media-breakpoint-up(lg) {
			max-width: 340px;
		}
	}
}