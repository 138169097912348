.calendly-overlay {
  background-color: white;
  .calendly-popup {
    box-shadow: $box-shadow;
    .calendly-popup-content {
      background-color: white;
    }
  }
  .calendly-popup-close {
    position: absolute;
    right: 33px;
    top: 33px;
    width: 48px;
    height: 48px;
    background-image: url("../assets/images/icons/close.svg");
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: $yellow;
    border-radius: 50%;
    z-index: 10;
    &:hover {
      background-image: url("../assets/images/icons/close.svg");
      box-shadow: none;
    }
  }
}
