.main-header {
  display: flex;
  align-items: center;
  padding-top: 25px;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 11;
  background-color: transparent;
  transition: $transition-time;
  padding-left: 24px;
  padding-right: 24px;
  position: fixed;
  background-color: #ffffff;
  @include media-breakpoint-up(md) {
    padding-left: 45px;
    padding-right: 45px;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 32px;
  }
  &.header-fixed {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: white;
    box-shadow: 0 3px 6px #48484812;
    z-index: 20;
  }
  .header-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
    @include media-breakpoint-up(lg) {
      justify-content: space-between;
      align-items: center;
      padding-right: 12px;
      padding-left: 12px;
    }
  }
  a,
  a:hover {
    background-image: none;
  }
  .logo-wrapper {
    display: flex;
    align-items: center;
    &:hover {
      text-decoration: none;
    }
  }
  &__logo {
    object-fit: contain;
  }
  .header-btn {
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
    }
  }
  .menu-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 48px;
    align-items: center;
    border: none;
    padding: 15px;
    transition: background-color 0.25s ease-in-out;
    @include media-breakpoint-up(xl) {
      display: none;
    }
    &--close {
      align-items: center;
    }
    &:active,
    &:hover,
    &:focus {
      outline: none;
    }
    span {
      display: flex;
      justify-self: center;
      height: 2px;
      border-radius: 8px;
      transition: 0.3s;
      width: 16px;
    }
    &:active,
    &:hover {
      span {
        width: 16px;
      }
    }
  }
  .nav-list {
    display: none;
    @include media-breakpoint-up(lg) {
      display: flex;
      overflow: hidden;
    }
  }
}

.top-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    width: 100%;
  }
}

.top-button-col {
  @include media-breakpoint-up(lg) {
    display: flex;
    justify-content: flex-end;
  }
}

.menu-line {
  position: absolute;
}

.mobile-menu {
  padding-top: 24px;
  padding-bottom: 94px;
  padding-left: 24px;
  padding-right: 24px;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: white;
  overflow: auto;
  display: none;
  text-align: center;
  &__content {
    margin-top: 130px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    #menu-square1 {
      bottom: 20%;
      right: 0;
    }
  }
  .btn {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5rem;
  }
  &__list {
    padding-left: 0;
    list-style: none;
    li {
      text-align: center;
      &:not(:last-of-type) {
        margin-bottom: 4.2rem;
      }
      a {
        color: $text-color;
        font-size: 1.6rem;
        font-weight: 600;
        padding-bottom: 2rem;
        display: inline;
        &.current {
          position: relative;
          &:after {
            content: "";
            display: block;
            position: absolute;
            bottom: 1rem;
            height: 2px;
            width: 100%;
            left: 50%;
            transform: translateX(-50%);
            background-color: $link-color;
          }
        }
      }
    }
  }
}

.menu-btn {
  background-color: $yellow;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  span {
    height: 2px;
    background-color: white;
    display: inline-block;
    margin-top: 2px;
    margin-bottom: 2px;
    &.sr-only {
      width: 0;
    }
  }
}

.sq-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border: 0;
  overflow: hidden;
  z-index: -1;
  .square-positioner {
    width: 100%;
    height: 100%;
  }
  #menu {
    &-square1 {
      width: 159px;
      height: 159px;
      bottom: 20%;
      right: -104px;
      position: absolute;
      border-radius: 20px 0 0 100px;
    }
    &-square2 {
      width: 300px;
      height: 300px;
      background-color: $light2;
      left: -243px;
      top: 30%;
      position: absolute;
      border-radius: 0 100px 20px 0;
    }
    &-orbit1 {
      right: -102px;
      top: 50%;
      transform: translateY(-50%);
    }
    &-orbit2 {
      top: 173px;
      left: -10px;
    }
  }
}

.logo-tablet {
  display: none;
  @include media-breakpoint-up(lg) {
    display: block;
  }
  @include media-breakpoint-up(xl) {
    display: none;
  }
}

.logo-mobile {
  display: block;
  @include media-breakpoint-up(lg) {
    display: none;
  }
  @include media-breakpoint-up(xl) {
    display: block;
  }
}
